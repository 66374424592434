.eRosterLogs {
  .tableLogs {
    padding-bottom: 50px;
    .MuiTableRow-head {
      th {
        font-size: 12px;
        color: #979797;
      }
    }

    .MuiTableBody-root {
      tr * {
        font-size: 12px;
      }
    }

    .date {
      max-width: 100px;
      .dateValue {
        display: block;
        color: #979797;
      }
    }

    .user {
      max-width: 100px;
      @include text-ellipsis();
      .userName {
        font-weight: bold;
      }
    }

    .action {
      max-width: 180px;
      .link {
        font-weight: normal;
        background: none;
        color: #126ef8;
        height: auto;
        padding: 0;
        margin: 0;
        display: block;
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }

      .desc {
        color: #979797;
      }
    }
  }
}
