// Overrides global styles coming from @speedoc/design-assets that mess up material-ui stylings
// Remove these overrides when we have removed design-assets from jarvis in the future

.MuiInputBase-root {
  textarea {
    border-radius: 0;
  }
  textarea:first-child {
    box-shadow: unset;

    &:focus {
      color: unset;
    }
  }
}

.MuiButtonBase-root {
  height: unset;
}

.PrivatePickersYear-yearButton {
  padding: unset;
}
