.dispatcherMap {
  position: relative;

  .dateRangeRooster {
    margin-right: $sd-spacing-sm;
  }

  .map {
    position: relative;
    @import "marker";
  }
}
