.containerCheckBox {
  display: block;
  position: relative;
  padding-left: $sd-spacing-xl;
  line-height: $sd-spacing-sm + $sd-spacing-xxs;
  margin-bottom: $sd-spacing-xs;
  cursor: pointer;
  font-size: $sd-spacing-sm;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &[disabled] {
    cursor: default;
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: $sd-spacing-sm + $sd-spacing-xxs;
    height: $sd-spacing-sm + $sd-spacing-xxs;
    border-radius: $sd-border-radius-xs;
    box-shadow: 2px 2px 8px 0 #a7b1bf, -5px -3px 8px 0 #ffffff, inset 1px 1px 6px 0 #d9e2e7;
    background-color: $sd-background-light;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
      content: "";
      width: $sd-spacing-sm + $sd-spacing-xxs;
      height: $sd-spacing-sm + $sd-spacing-xxs;
      border-radius: $sd-border-radius-xs;
      -webkit-filter: blur(1px);
      filter: blur(1px);
      border: solid 2px #ffffff;
      position: absolute;
    }

    img {
      width: $sd-spacing-sm;
    }
  }
}
