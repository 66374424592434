$height: $element-height-md;

.input {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px $sd-background-light inset;
  }
  position: relative;
  display: flex;

  &.sm {
    height: $element-height-sm;
    &.error:before,
    &.warning:before,
    &.success:before {
      height: $element-height-sm;
    }

    input {
      height: $element-height-sm;
      font-size: $font-size-sm;
    }
  }

  &.error,
  &.warning,
  &.success {
    input {
      border: none;
    }
  }

  &:before {
    position: absolute;
    height: $element-height-md;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: $sd-border-radius-sm;
    background-color: $sd-background-light;
    -webkit-filter: blur(1px);
    filter: blur(1px);
    content: "";
    z-index: 1;
  }

  &.error:before {
    border: solid 1px $sd-red-error;
  }
  &.warning:before {
    border: solid 1px $sd-orange-warning;
  }
  &.success:before {
    border: solid 1px $sd-green-success;
  }

  input {
    height: $element-height-md;
    width: 100%;
    border-radius: $sd-border-radius-sm;
    @include boxShadow-md-up();
    border: solid 1px $sd-green-lighter;
    background-color: transparent;
    margin-bottom: $sd-spacing-sm;
    font-family: "HelveticaNeueLTProRoman", Arial, Helvetica, sans-serif;
    font-size: $font-size-lg;
    line-height: 40px;
    letter-spacing: normal;
    color: $sd-textcolor-black;
    padding-left: $sd-spacing-sm;
    outline: none;
    z-index: 2;

    &::placeholder,
    :-ms-input-placeholder,
    ::-ms-input-placeholder {
      color: $sd-textcolor-black-light;
      line-height: 40px;
    }
    &:focus {
      color: $sd-textcolor-green;
    }
    &:disabled {
      color: $sd-textcolor-black-disabled;
    }
  }
}
