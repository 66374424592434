select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px $sd-background-light inset;
}

.select-wrapper {
  position: relative;
  font-size: $font-size-lg;

  &.sm {
    height: $element-height-sm;
    font-size: $font-size-sm;
    button {
      height: $element-height-sm;
      line-height: $element-height-sm;
    }

    select {
      top: $element-height-sm - 1;
    }

    .select-list .select-scroll-list .select-list-item {
      padding: $sd-spacing-xxs $sd-spacing-md;
    }
  }
  background-color: $sd-background-light;
  user-select: none;
  margin-bottom: $sd-spacing-sm;

  &:invalid {
    button {
      color: $sd-textcolor-black-light;
    }
  }

  &[disabled] {
    button {
      color: $sd-textcolor-black-disabled;
      cursor: default;
    }
  }

  &.opened {
    .select-header {
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .select-list {
      @include boxShadow-md();
      background-color: $sd-background-light;
    }
  }

  &.withoutBorders {
    button {
      border: none;
      border-radius: 0;
      box-shadow: none;
      margin-bottom: $sd-spacing-xs;
      padding-left: $sd-spacing-md;
    }
    .select-header {
      width: auto;

      .select-header-title {
        padding: 0;
        margin-right: $sd-spacing-md;
      }
    }

    .select-list {
      border: solid 1px $sd-green-lighter;
      border-radius: $sd-border-radius-md;
    }
  }
  button {
    font-family: "HelveticaNeueLTProRoman", Arial, Helvetica, sans-serif;
    letter-spacing: normal;
    color: $sd-textcolor-black;
    border: solid 1px $sd-green-lighter;
    border-radius: $sd-border-radius-md;
  }

  .select-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    background-color: transparent;
    cursor: pointer;
    margin: 0;
    width: 100%;
    height: $element-height-md;
    line-height: $element-height-md;
    @include boxShadow-md-up();

    &.sm {
      img {
        width: auto;
        &.chevron {
          width: auto;
        }
      }

      &.error:before,
      &.warning:before,
      &.success:before {
        height: $element-height-sm;
      }
    }
    padding: 0;

    img {
      margin-right: $sd-spacing-xs;
      &.chevron {
        width: 17px;
      }
      width: 27px;
    }

    .select-header-title {
      padding-left: $sd-spacing-sm;
    }

    &.error,
    &.warning,
    &.success {
      input {
        border: none;
      }
    }

    &.error:before,
    &.warning:before,
    &.success:before {
      position: absolute;
      height: $element-height-md;
      width: 100%;
      top: 0;
      left: 0;
      border-radius: $sd-border-radius-md;
      -webkit-filter: blur(1px);
      filter: blur(1px);
      background: inherit;
      background-clip: border-box;
      content: "";
    }

    &.error:before {
      border: solid 1px $sd-red-error;
    }
    &.warning:before {
      border: solid 1px $sd-orange-warning;
    }
    &.success:before {
      border: solid 1px $sd-green-success;
    }
  }
  select {
    -webkit-appearance: none;
    height: 1px;
    border: none;
    width: 1px;
    margin-left: 50%;
    position: absolute;
    top: $element-height-md - 1;
  }
  .select-list {
    position: absolute;
    z-index: 10;
    width: 100%;
    border-bottom-left-radius: $sd-border-radius-md;
    border-bottom-right-radius: $sd-border-radius-md;
    @include boxShadow-md();
    border: solid 1px $sd-green-lighter;
    border-top: none;
    background-color: white;
    text-align: left;
    -webkit-overflow-scrolling: touch;
    margin-bottom: $sd-spacing-sm;
    &::-webkit-scrollbar {
      width: $sd-spacing-xxs;
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px $sd-green-lighter;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px $sd-green-primary;
    }
    .select-scroll-list {
      overflow-y: auto;
      .select-list-item {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        width: 100%;
        padding: $sd-spacing-sm $sd-spacing-md;
        height: auto;
        white-space: nowrap;
        text-overflow: ellipsis;
        background: none;
        outline: none;
        cursor: pointer;
        text-align: left;
        border-radius: 0;
        margin: 0;
        border: 0;
        box-shadow: none;
        font-size: inherit;

        &.selected {
          background-color: $sd-green-primary;
          color: white;
        }

        &:hover {
          background-color: rgba(98, 203, 201, 0.15);
          &:last-child {
            border-bottom-left-radius: $sd-border-radius-md;
            border-bottom-right-radius: $sd-border-radius-md;
          }
        }
      }
    }
  }
}
