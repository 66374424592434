.taskDetail {
  .updateButton {
    background: #d9f5f3;
    color: #00c3b5;
    border-radius: 6px;
    font-weight: 600;

    &:hover {
      background: #d9f5f3;
      color: #00c3b5;
    }
  }
}
