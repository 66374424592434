.selectUM {
  position: relative;

  .inputUM {
    margin-bottom: 0;
    input {
      border-radius: 6px;
      border: solid 1.5px #e7edf4;
      font-size: 12px;
      cursor: pointer;
      ::selection {
        background: #ffb7b7;
      }
      ::-moz-selection {
        background: #ffb7b7;
      }
    }

    .inputContainer {
      input {
        padding: 0 30px 0 10px;
      }
      span.selectIcon {
        position: absolute;
        right: 10px;
        top: 9px;
        svg {
          height: 12px;
          width: 12px;
          path {
            fill: black !important;
          }
        }
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
    }
  }

  .list {
    position: absolute;
    top: 35px;
    z-index: 2;
    cursor: pointer;
    width: 100%;
    border: solid 1px #d5f2f0;
    border: none;
    max-height: 200px;
    overflow-y: auto;
    font-size: 12px;
    font-family: "Open Sans";
    padding: 8px 0;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    @include scrollbar();

    .listItem {
      display: flex;
      justify-content: space-between;
      padding: $sd-spacing-xs $sd-spacing-sm;
      letter-spacing: -0.12px;
      height: 44px;
      align-items: center;
      color: #000000;
      &:hover {
        background-color: rgba(218, 218, 218, 0.2);
      }

      .content {
        display: flex;
        flex-direction: column;

        .subtitle {
          font-size: 10px;
          letter-spacing: -0.1px;
          color: #6c6c6c;
        }
      }

      .info {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        font-family: "Open Sans";
        font-size: 10px;
        font-weight: bold;
        color: #1065e4;
        svg {
          height: 16px;
          margin-right: 3px;
          path:first-child {
            fill: #1065e4;
          }
        }
      }
    }
  }
}
