.eRosterProviderAssignment {
  .headerUM {
    flex-direction: column;
    margin: 0;
    padding: 0;

    .topHeader {
      display: flex;
      padding-top: 16px;
      align-items: center;
      gap: 24px;
      width: -webkit-fill-available;
      margin: 0 16px;

      .title {
        margin-left: 40px;
      }

      svg {
        cursor: pointer;
      }
    }

    .bottomHeader {
      width: 100%;
    }

    @import "../../universalModal/selectMultiple.scss";

    .selectMultiple {
      position: absolute;
      top: 0px;
      right: 0px;
      .selectMultipleButton {
        box-shadow: unset;
        height: 30px;
        margin: 0px;
        border-left: 1px solid #e7ecf4;
        border-top-left-radius: unset;
        border-bottom-left-radius: unset;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        .chevron {
          margin-left: 8px;
          svg {
            path {
              fill: #9bb0c4;
            }
          }
        }
      }
      .selectMultipleOptions {
        top: 35px;
        .containerCheckBoxUM {
          cursor: not-allowed;
          &.checked .checkmark {
            background-color: #d3d3d3;
            &:before {
              border: solid 1px #d3d3d3;
            }
          }
        }
      }
    }
  }

  .inputUM {
    margin: 0 16px;
    width: auto;
  }

  .providers {
    flex-grow: 1;
    padding: 16px 24px 0px 52px;
    display: flex;
    flex-direction: column;
    overflow: hidden; // fix for children is not showing overflow properly

    &.noShrink {
      flex-shrink: 0;
    }
    &.equalBasis {
      flex-basis: 50%;
    }

    .title {
      color: #4f657a;
      display: flex;
      justify-content: space-between;

      .serviceTitle {
        min-width: 40%;
      }
    }

    .providersList {
      flex-grow: 1;
      overflow-y: auto;
      @include scrollbar();

      .providerData {
        padding: 8px 0px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #eeeeee;

        &.clickable {
          cursor: pointer;
        }

        .providerDetail {
          flex-grow: 1;
          display: flex;
          align-items: center;

          .avatarUM {
            margin-right: 6px;
          }
          .name {
            display: table;
            .fullName {
              display: table-row;
            }
            .type {
              display: table-row;
              color: #6c6c6c;
              font-size: 10px;
            }
          }
        }

        .serviceDetail {
          min-width: 40%;

          &.removeable {
            min-width: 35%;
          }

          .tagUM {
            padding: 2px 8px;
            background-color: #dde8ef;
            font-weight: 600;
            text-transform: capitalize;
            border-radius: 4px;
          }
          .tagUM:not(:last-child) {
            margin-right: 4px;
          }
        }

        .actionButton {
          padding-right: 8px;
          display: flex;
          align-items: center;
          cursor: pointer;

          svg {
            width: 15px;
            height: 15px;
          }
        }
      }
      .providerData:last-child {
        border-bottom: none;
      }
    }
  }

  .footerContainer {
    padding: $sd-spacing-sm 31px 26px $sd-spacing-sm;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .addProviders {
      margin: 0;
      padding: 0;
      height: auto;
      background-color: inherit;
      color: #00c3b5;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
    }
  }

  .loader {
    > div {
      margin: auto;
    }
  }
}
