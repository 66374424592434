.circleStep {
  width: $element-height-md;
  height: $element-height-md;
  transform: rotate(90deg);
  border-radius: $sd-spacing-xl;
  border-style: solid;
  border-width: 1px;
  border-image-source: linear-gradient(242deg, #e2eaf0 85%, white 16%);
  border-image-slice: 0;
  box-shadow: -3px 2px 6px 0 white, 3px -2px 8px 0 #e3eaf1, inset -10px 14px 13px 0 white, inset 9px 0 5px 0 #f2f7fd;
  background-color: #f6fbfe;

  .circleStepIcon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: $element-height-md;
    height: $element-height-md;

    img {
      margin-top: -4px;
    }
  }

  &.sm {
    width: $element-height-sm;
    height: $element-height-sm;
    .circleStepIcon {
      width: $element-height-sm;
      height: $element-height-sm;
      img {
        margin-left: -2px;
      }
    }
  }
}
