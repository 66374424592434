.currentTimeCursor {
  position: relative;
  .redDotWrapper {
    position: absolute;
    .redDot {
      display: block;
      position: sticky;
      top: 78px;
      height: 9px;
      width: 9px;
      background-color: red;
      border-radius: 50%;
      z-index: 5;
    }
  }
  .redLineWrapper {
    display: flex;
    position: absolute;
    width: 10px;
    .redLine {
      width: 50%;
      border-right: 1px solid red;
      z-index: 3;
    }
  }
}
