.selectMultiple {
  width: fit-content;
  position: relative;
  .selectMultipleButton {
    user-select: none;
    min-width: 96px;
    display: flex;
    align-items: center;
    border: 1px solid #d1e4ec;
    border-radius: 12px;
    height: 40px;
    font-size: 14px;
    font-family: "Open Sans";
    padding: 8px 12px;
    font-weight: 600;
    margin-bottom: unset;
    color: #3ebebc;
    background-color: #f9fdff;
    box-shadow: 1px 0px 8px 0px rgba(192, 200, 212, 0.4);
    .chevron {
      display: flex;
      align-self: center;
      margin: 4px 0px 0px 16px;
      svg {
        path {
          fill: #3ebebc;
        }
      }
    }
    &.disabled {
      cursor: not-allowed;
    }
  }

  .selectMultipleOptions {
    height: max-content;
    position: absolute;
    top: 48px;
    right: 0px;
    z-index: 1;
    border-radius: 5px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    z-index: 2;
    width: max-content;
    hr {
      margin: 0px;
      background-color: #eeeeee;
      height: 1px;
      border: 0;
    }
    li {
      margin: 5px 0px;
      padding: 6px 8px;
      label {
        input {
          height: 100%;
          margin: 0;
          top: 0;
          left: 0;
        }
      }
      .text {
        color: black;
      }
    }
  }
}
