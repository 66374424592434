.services {
  display: inline-block;
  position: relative;
  height: 100%;

  .serviceGroup {
    position: absolute;
    height: 100%;
    z-index: 1;
    &:hover,
    &.open {
      z-index: 6;
    }
  }

  .service {
    position: absolute;
    cursor: pointer;
    border-radius: 4px;
    height: 50%;
    word-break: break-all;
    transition: bottom 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    z-index: 1;

    &.parent {
      z-index: 2;
    }

    &:hover {
      z-index: 6;
    }

    .totalGroup {
      position: absolute;
      top: -8px;
      left: -8px;
      width: 16px;
      height: 16px;
      text-align: center;
      border-radius: 50%;
      background-color: #3ca9a6;
      color: #fff;
    }

    &.task {
      bottom: 0;
      bottom: 0;
      z-index: 2;
      &:hover {
        z-index: 6;
      }
    }

    .serviceWrapper {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: flex-end;
      padding: 1px;

      .title {
        &.ellipsis {
          @include text-ellipsis();
        }
      }
      .hours {
        line-height: 12px;
      }
    }
    .providers {
      margin-bottom: 16px;
    }
    .caseWrap {
      display: flex;
      justify-content: flex-start;
      .case {
        margin-right: 8px;
        color: #f9d392 !important;
      }
      .followUp {
        color: #5bb4ff !important;
      }
    }
  }
}
