@mixin scrollbar() {
  &::-webkit-scrollbar {
    width: $sd-spacing-xxs;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px $sd-green-lighter;
    border-radius: $sd-border-radius-sm;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: $sd-border-radius-sm;
    -webkit-box-shadow: inset 0 0 6px $sd-green-primary;
  }
}

@mixin text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
