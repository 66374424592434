$font-size-jarvis-xs: 10px;
$font-size-jarvis-sm: 12px;
$font-size-jarvis-md: 14px;

$grey-primary: #c3d9e5;
$grey-secondary: #5a5a5e;
$text-color-captions: #a5a5ad;
$text-color-black: #141417;
$text-color-yellow: #d09329;
$button-group-blue: #52c5c4;

$um-warning: #fff4cc;
$um-danger: #ffe9e9;

$e-roster-roster-text-color: #b8bdd4;

$e-roster-video-text-color: #cfe7fa;

$e-roster-visit-text-color: #caeceb;

$e-roster-clinic-text-color: #b4c0de;

$e-roster-delivery-text-color: #c16651;
