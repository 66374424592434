.sdTimePicker,
.schedule {
  .react-datepicker-popper {
    margin-top: 0 !important;
    margin-bottom: $sd-spacing-md;
    min-width: 200px;

    .react-datepicker {
      background-color: $sd-background-light !important;
      display: grid;
      border: solid 1px $sd-green-lighter;
      border-radius: $sd-border-radius-lg;
      box-shadow: 10px 10px 20px 0 rgba(209, 217, 230, 0.4), -10px -10px 20px 0 #ffffff;

      .react-datepicker__day:hover,
      .react-datepicker__month-text:hover,
      .react-datepicker__year-container-text:hover {
        border-radius: 5px;
        background-color: #f0f0f0;
      }

      &:before {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0.13;
        border-radius: $sd-border-radius-md;
        -webkit-filter: blur($sd-spacing-xl);
        filter: blur($sd-spacing-xl);
        background-image: linear-gradient(163deg, #f4f4f4 26%, #35b0ad 92%);
        content: "";
      }

      .react-datepicker__day--disabled,
      .react-datepicker__month-text--disabled,
      .react-datepicker__quarter-text--disabled {
        color: #ccc !important;
      }

      .react-datepicker__day-names,
      .react-datepicker__month,
      .react-datepicker__time-container,
      .react-datepicker__day-name,
      .react-datepicker__day,
      .react-datepicker__time-name {
        color: rgba(32, 0, 0, 0.6);
      }

      .react-datepicker__time-container .react-datepicker__time {
        background: transparent !important;
      }

      .react-datepicker__triangle {
        border: 0;
      }

      .react-datepicker__navigation {
        z-index: 7;
      }

      .react-datepicker__header {
        background-color: transparent !important;
      }

      .react-datepicker__month-container {
        z-index: 6;
      }

      .react-datepicker__today-button {
        background-color: transparent;
        border: none;
        color: $sd-textcolor-green;
        z-index: 6;
      }

      .react-datepicker__day--keyboard-selected,
      .react-datepicker__day--selected {
        color: white !important;
        background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMzJweCIgaGVpZ2h0PSIzM3B4IiB2aWV3Qm94PSIwIDAgMzIgMzMiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+T3ZhbDwvdGl0bGU+CiAgICA8ZyBpZD0iU3ltYm9scyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IkNhbGFuZGFyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNjkuMDAwMDAwLCAtMjAzLjAwMDAwMCkiIGZpbGw9IiM2MkUzQzMiPgogICAgICAgICAgICA8ZyBpZD0iR3JvdXAtNSI+CiAgICAgICAgICAgICAgICA8ZyBpZD0iR3JvdXAtNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNjkuMDAwMDAwLCAyMDMuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgPHBhdGggZD0iTTIwLjUsMzIuNDE4NDY5MiBDMjkuMzM2NTU2LDMyLjQxODQ2OTIgMzMuNzIxNDk5LDIwLjk1MzQ4IDMwLDEyLjM1NDU5NTQgQzI2LjI3ODUwMSwzLjc1NTcxMDY2IDI0LjgzNjU1NiwwIDE2LDAgQzcuMTYzNDQ0LDAgLTIuMDQyODEwMzdlLTEzLDcuMTYzNDQ0IC0yLjA0MjgxMDM3ZS0xMywxNiBDLTIuMDQyODEwMzdlLTEzLDI0LjgzNjU1NiAxMS42NjM0NDQsMzIuNDE4NDY5MiAyMC41LDMyLjQxODQ2OTIgWiIgaWQ9Ik92YWwiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+)
          no-repeat left center;
        background-size: contain;
      }

      .react-datepicker__time-container
        .react-datepicker__time
        .react-datepicker__time-box
        ul.react-datepicker__time-list
        li.react-datepicker__time-list-item--selected {
        background-color: $sd-green-light;
      }

      .react-datepicker__current-month,
      .react-datepicker-time__header,
      .react-datepicker-year-header {
        font-family: "HelveticaNeueLTProRoman";
        font-size: $font-size-sm;
      }

      select.react-datepicker__month-select,
      select.react-datepicker__year-select {
        border: none;
        background: none;
        cursor: pointer;
      }

      .react-datepicker__navigation--next {
        background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTRweCIgaGVpZ2h0PSI4cHgiIHZpZXdCb3g9IjAgMCAxNCA4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPHRpdGxlPkZpbGw8L3RpdGxlPgogICAgPGcgaWQ9IlN5bWJvbHMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxwYXRoIGQ9Ik0xMy42Njc0NTA1LDAuOTEyNDI5Mzc5IEwxMi45OTM2MzMzLDAuMzAxMzQ2ODAxIEMxMi43NjAxMzExLDAuMTAwNDY3OTU2IDEyLjQ4NzQxMjgsLTEuMTM2ODY4MzhlLTEzIDEyLjE3NjEwOCwtMS4xMzY4NjgzOGUtMTMgQzExLjg1ODM4MjUsLTEuMTM2ODY4MzhlLTEzIDExLjU4ODkwNiwwLjEwMDQ2Nzk1NiAxMS4zNjcyMzc4LDAuMzAxMzQ2ODAxIEw3LjAwMDAzMTQ3LDQuMjYwNzcxNTYgTDIuNjMyODU2NTgsMC4zMDE0NjA5MzcgQzIuNDExMjE5ODcsMC4xMDA1ODIwOTIgMi4xNDE2ODA0MiwwLjAwMDExNDEzNTcwNyAxLjgyNDA4MDg2LDAuMDAwMTE0MTM1NzA3IEMxLjUxMjYxODY0LDAuMDAwMTE0MTM1NzA3IDEuMjM5OTYzMzEsMC4xMDA1ODIwOTIgMS4wMDY0Mjk2LDAuMzAxNDYwOTM3IEwwLjM0MTQ1NjUwNiwwLjkxMjU0MzUxNCBDMC4xMTM3NzY4NywxLjExODgxNTI3IDAsMS4zNjYwMDQ2OCAwLDEuNjUzODgzNDcgQzAsMS45NDcxMjY2MyAwLjExMzkwMjc2NSwyLjE5MTUxOTcyIDAuMzQxNDI1MDMyLDIuMzg3MDA1NjUgTDYuMTkxMjI0MjcsNy42OTA0NjM5NiBDNi40MDY5NzU0Myw3Ljg5Njc2NDI1IDYuNjc2NDIwNDcsOCA3LDggQzcuMzE3NTA1MTMsOCA3LjU5MDEyODk5LDcuODk2NzkyNzkgNy44MTc1ODgzMSw3LjY5MDQ2Mzk2IEwxMy42Njc0MTksMi4zODcwMDU2NSBDMTMuODg5MDg3MiwyLjE4NjAxMjY3IDE0LDEuOTQxNjQ4MTIgMTQsMS42NTM4ODM0NyBDMTQsMS4zNzEzNjkwNiAxMy44ODkxMTg3LDEuMTI0MzIyMzIgMTMuNjY3NDUwNSwwLjkxMjQyOTM3OSBaIiBpZD0iRmlsbCIgZmlsbD0iIzE0MTQxNyIgZmlsbC1ydWxlPSJub256ZXJvIj48L3BhdGg+CiAgICA8L2c+Cjwvc3ZnPg==)
          no-repeat center center;
        background-size: 10px;
        transform: rotate(-90deg);
        border: none;
        top: 14px;
      }

      .react-datepicker__navigation--previous {
        background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTRweCIgaGVpZ2h0PSI4cHgiIHZpZXdCb3g9IjAgMCAxNCA4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPHRpdGxlPkZpbGw8L3RpdGxlPgogICAgPGcgaWQ9IlN5bWJvbHMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxwYXRoIGQ9Ik0xMy42Njc0NTA1LDAuOTEyNDI5Mzc5IEwxMi45OTM2MzMzLDAuMzAxMzQ2ODAxIEMxMi43NjAxMzExLDAuMTAwNDY3OTU2IDEyLjQ4NzQxMjgsLTEuMTM2ODY4MzhlLTEzIDEyLjE3NjEwOCwtMS4xMzY4NjgzOGUtMTMgQzExLjg1ODM4MjUsLTEuMTM2ODY4MzhlLTEzIDExLjU4ODkwNiwwLjEwMDQ2Nzk1NiAxMS4zNjcyMzc4LDAuMzAxMzQ2ODAxIEw3LjAwMDAzMTQ3LDQuMjYwNzcxNTYgTDIuNjMyODU2NTgsMC4zMDE0NjA5MzcgQzIuNDExMjE5ODcsMC4xMDA1ODIwOTIgMi4xNDE2ODA0MiwwLjAwMDExNDEzNTcwNyAxLjgyNDA4MDg2LDAuMDAwMTE0MTM1NzA3IEMxLjUxMjYxODY0LDAuMDAwMTE0MTM1NzA3IDEuMjM5OTYzMzEsMC4xMDA1ODIwOTIgMS4wMDY0Mjk2LDAuMzAxNDYwOTM3IEwwLjM0MTQ1NjUwNiwwLjkxMjU0MzUxNCBDMC4xMTM3NzY4NywxLjExODgxNTI3IDAsMS4zNjYwMDQ2OCAwLDEuNjUzODgzNDcgQzAsMS45NDcxMjY2MyAwLjExMzkwMjc2NSwyLjE5MTUxOTcyIDAuMzQxNDI1MDMyLDIuMzg3MDA1NjUgTDYuMTkxMjI0MjcsNy42OTA0NjM5NiBDNi40MDY5NzU0Myw3Ljg5Njc2NDI1IDYuNjc2NDIwNDcsOCA3LDggQzcuMzE3NTA1MTMsOCA3LjU5MDEyODk5LDcuODk2NzkyNzkgNy44MTc1ODgzMSw3LjY5MDQ2Mzk2IEwxMy42Njc0MTksMi4zODcwMDU2NSBDMTMuODg5MDg3MiwyLjE4NjAxMjY3IDE0LDEuOTQxNjQ4MTIgMTQsMS42NTM4ODM0NyBDMTQsMS4zNzEzNjkwNiAxMy44ODkxMTg3LDEuMTI0MzIyMzIgMTMuNjY3NDUwNSwwLjkxMjQyOTM3OSBaIiBpZD0iRmlsbCIgZmlsbD0iIzE0MTQxNyIgZmlsbC1ydWxlPSJub256ZXJvIj48L3BhdGg+CiAgICA8L2c+Cjwvc3ZnPg==)
          no-repeat center center;
        background-size: 10px;
        transform: rotate(90deg);
        border: none;
        top: 14px;
      }
    }
  }

  .input .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker__time-container--with-today-button {
    right: -93px;
    height: 316px;
    border: solid 1px $sd-green-lighter !important;
    border-radius: $sd-border-radius-lg;
    box-shadow: 10px 10px 20px 0 rgba(209, 217, 230, 0.4), -10px -10px 20px 0 #ffffff;
    background: $sd-background-light;

    &:before {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0.13;
      border-radius: $sd-border-radius-md;
      -webkit-filter: blur($sd-spacing-xl);
      filter: blur($sd-spacing-xl);
      background-image: linear-gradient(163deg, #f4f4f4 26%, #35b0ad 92%);
      content: "";
      left: 0;
      top: 0;
    }
  }

  .react-datepicker__triangle {
    border-bottom-color: $sd-green-lighter !important;
  }

  ul.react-datepicker__time-list {
    height: 260px;
    @include scrollbar();
  }
}
