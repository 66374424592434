.providerAssignment {
  .headerUM {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
    margin: 0;
    padding: 0;

    @import "../selectMultiple.scss";

    .topHeader {
      display: flex;
      padding-top: 16px;
      align-items: center;
      gap: 16px;
      margin: 0 16px;
      svg {
        cursor: pointer;
      }
    }
    .middleHeader {
      display: flex;
      padding: 8px 16px;
      font-weight: 600;

      .serviceType {
        flex-grow: 1;
        padding-left: 40px;
      }
      .timer {
        display: flex;
        align-items: center;

        .title,
        .renderTime {
          font-family: Open Sans;
          font-size: 12px;
        }
        .renderTime {
          display: flex;
          justify-content: center;
          align-self: center;
          margin-left: 8px;
          padding: 2px 3px;
          background-color: #dfecf0;
          border-radius: 4px;
          &.danger {
            color: #d30000;
          }
        }
      }
    }

    .selectMultiple {
      position: absolute;
      top: 0px;
      right: 0px;
      .selectMultipleButton {
        box-shadow: unset;
        height: 30px;
        margin: 0px;
        border-left: 1px solid #e7ecf4;
        border-top-left-radius: unset;
        border-bottom-left-radius: unset;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        .chevron {
          margin-left: 8px;
          svg {
            path {
              fill: #9bb0c4;
            }
          }
        }
      }
      .selectMultipleOptions {
        top: 35px;
      }
    }
  }

  .inputUM {
    margin: 0 16px;
    width: auto;
  }

  .providers {
    padding: 16px 0px;

    .titlesContainer {
      padding: 0px 0px 0px 15px;
      margin-bottom: 16px;
      color: #4f657a;

      .providersTitle {
        .bold {
          font-weight: 700;
        }
      }
    }

    .providersList {
      .providerData {
        padding-right: 10px;
        cursor: pointer;

        &:hover {
          background-color: #e5f9f8;
        }

        &.selected,
        &.notAvailable {
          .providerDetail {
            opacity: 0.4;
          }
        }

        &.selected {
          background-color: #f5fff5;
        }

        .providerDetail {
          padding: 8px 0px;
          border-bottom: 1px solid #eeeeee;

          .name {
            .type {
              color: #6c6c6c;
              font-size: 10px;
            }

            .break {
              background-color: #fffc54;
              padding: 2px;
              border-radius: 4px;
              margin: 4px 0px;
              width: fit-content;
              font-family: "Open Sans";
              font-size: 10px;
              font-weight: 600;
              line-height: 10px;
              letter-spacing: unset;
              &:last-child {
                margin-bottom: 0px;
              }
            }
          }

          .distance {
            color: #6c6c6c;
            font-size: 10px;
          }

          .serviceDetail {
            .tagUM {
              border-radius: 4px;
              text-transform: capitalize;
              background-color: #dde8ef;
            }
            .tagUM:not(:last-child) {
              margin-right: 4px;
            }
          }
        }
      }
    }
  }

  .selectedProviders {
    margin: 16px 16px -11px 15px;
    .tagInfoUM {
      margin-right: 8px;
      margin-bottom: 8px;
      &.main {
        border: 1px solid $sd-green-primary;
      }
      .setMain {
        color: $sd-green-primary;
      }
    }
    .selectedProviderList {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      padding-bottom: 10px;
      cursor: pointer;
      border-bottom: 1px solid #eeeeee;

      &.disabled {
        opacity: 0.6;
        cursor: default;
      }

      .provider {
        display: flex;
        align-items: center;
        min-width: 70%;
        .avatarUM {
          margin-right: 6px;
        }
        .providerDetail {
          margin-left: 6px;
          display: table;
          width: 100%;
          .name {
            display: flex;
            justify-content: space-between;
            .primaryInfo {
              width: 40%;
            }

            .setMain {
              width: 40%;
              color: $sd-green-primary;
            }
          }
          .type {
            display: table-row;
            color: #6c6c6c;
          }
        }
      }

      .actionButton {
        display: flex;
        align-items: center;

        .containerCheckBoxUM {
          display: inline-block;

          .text {
            float: left;
            margin-right: 8px;
          }

          .checkmark {
            position: relative;
          }

          &.checkboxMargin {
            margin-right: 10px;
          }
        }

        .adhoc {
          margin-right: 10px;
          font-family: "Open Sans";
          font-size: 12px;
          color: #979797;
        }

        svg {
          width: 15px;
          height: 15px;
        }
      }
    }
  }

  .footerUM {
    .footerContainer {
      display: flex;
      padding: $sd-spacing-sm 31px 26px $sd-spacing-sm;
      justify-content: flex-end;
      align-items: center;
    }
  }
  .loader {
    > div {
      margin: auto;
    }
  }
}
