.autoComplete {
  position: relative;
  .input {
    margin-bottom: 0;
    &.open {
      input {
        border-bottom: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
  .list {
    position: absolute;
    top: 40px;
    z-index: 2;
    cursor: pointer;
    width: 100%;
    border: solid 1px #d5f2f0;
    border-top: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #f9fdff;
    max-height: 300px;
    overflow-y: auto;
    font-size: 16px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    @include scrollbar();
    .listItem {
      padding: $sd-spacing-xs $sd-spacing-sm;
      &:hover {
        background-color: rgba(98, 203, 201, 0.15);
      }
    }
  }
}
