.settingsDispatcher {
  .flushLabel {
    font-size: 14px;
    color: #a5a5ad;
  }

  .button {
    margin-bottom: 0;
  }

  .buttonRed {
    color: #ff6b6b;
  }

  .timeFrameCheck {
    .MuiTypography-root {
      font-size: 10px;
      color: #a5a5ad;
    }
    .MuiSvgIcon-root {
      fill: #52c5c4;
    }
  }

  &.MuiDialogContent-root {
    color: #56738f;
    font-family: Open Sans;
    font-size: 15px;
    line-height: 20px;

    .title {
      font-family: Open Sans;
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
      margin-bottom: 8px;
    }

    .passwordTitle {
      display: block;
      margin-bottom: 8px;
    }

    .linkRed {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @import "dateFilter";
}
