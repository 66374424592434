.tabsUM {
  display: flex;
  margin-left: 16px;
  margin-right: 16px;
  border-bottom: 1px solid #eeeeee;
  .tabItem {
    padding: $sd-spacing-xxs $sd-spacing-sm;
    cursor: pointer;
    color: #4f657a;
    &.active {
      border-bottom: 4px solid $sd-green-primary;
      font-weight: 600;
      color: black;
    }
  }
}
