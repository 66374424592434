.taskTitles {
  min-height: 30px;

  .MuiTabs-indicator {
    height: 4px;
    background-color: #00c3b5;
  }

  button {
    color: #4f657a;
    font-weight: 600;
    padding: 4px 0px 10px 0px;
    margin: 0px 8px;
    text-transform: capitalize;
    min-width: unset;
    min-height: unset;
    font-size: 12px;

    &.Mui-selected {
      color: black;
    }
  }
}
