.adminCodes {
  font-family: "Helvetica Neue";
  box-shadow: -1px -2px 8px 0 rgba(207, 214, 226, 0.26);
  border-radius: 20px;

  .loaderSection {
    margin-left: $sd-spacing-sm;
  }

  .codeList {
    padding: $sd-spacing-xs $sd-spacing-md;
  }

  .dateRangeContainer {
    z-index: 3;
  }

  .codeListHeader {
    display: flex;
    font-size: 12px;
    color: $grey-secondary;
    margin-bottom: $sd-spacing-xs;
    padding: 0 $sd-spacing-sm;
    .sortContainer {
      position: relative;
      width: 15px;
      height: 22px;
      margin-left: $sd-spacing-xs;
      .buttonSort {
        cursor: pointer;
        &.asc {
          position: absolute;
          top: 0;
          transform: rotate(90deg);
        }
        &.desc {
          position: absolute;
          top: 10px;
          transform: rotate(-90deg);
        }
      }
    }
    .codeName {
      width: 125px;
      display: flex;
      align-items: center;
    }
    .codeDesc {
      width: 125px;
      display: flex;
      align-items: center;
    }
    .codeDates {
      width: 125px;
      padding: 0 $sd-spacing-xs;
      display: flex;
      align-items: center;
    }
    .codeDiscount {
      width: 125px;
      display: flex;
      align-items: center;
    }
    .codeItemTags {
      width: 125px;
      display: flex;
      align-items: center;
    }
    .codeRedeemed {
      width: 125px;
      display: flex;
      align-items: center;
    }
    .codeRedeemPerUser {
      width: 125px;
      display: flex;
      align-items: center;
    }
    .codeServices {
      flex-grow: 1;
    }
  }
  .codeListItemScroll {
    height: calc(100vh - 400px);
    overflow-y: scroll;
    @include scrollbar();
    .loader {
      > div {
        margin: auto;
      }
    }
    &.disableScroll {
      overflow-y: hidden;
    }
  }

  .codeListItem {
    display: flex;
    margin-bottom: $sd-spacing-xs;
    align-items: center;

    input {
      border: none;
      outline: none;
      border-bottom: 1px solid #a5a5ad64;
      background: transparent;
      width: 100%;
      padding-bottom: 5px;
      font-size: 12px;

      &:disabled {
        border-bottom: none;
      }
    }

    .codeFields {
      display: flex;
      padding: $sd-spacing-xs $sd-spacing-sm;
      border-radius: 10px;
      border: solid 1px #c3d9e5;
      &.expired {
        border-color: orange;
      }
      &.editing {
        border-color: #35b0ad;
        box-shadow: 4px 4px 8px 0 rgba(209, 217, 230, 0.4);
      }
      align-items: center;
      font-size: 12px;
      .codeName {
        width: 125px;
        padding: 0 5px;
        input {
          font-size: 14px;
        }
      }
      .codeDesc {
        width: 125px;
        padding: 0 $sd-spacing-xs;
      }
      .codeDates {
        width: 125px;
        padding: 0 $sd-spacing-xs;
        input {
          text-align: center;
          font-family: "Helvetica Neue";
          color: gray;
          border: none;
          border-bottom: 1px solid #a5a5ad64;
          width: 80px;
          outline: none;
          padding-bottom: 5px;
          background: transparent;
          &::-webkit-inner-spin-button,
          &::-webkit-calendar-picker-indicator {
            display: none;
            -webkit-appearance: none;
          }
          &::-webkit-datetime-edit-month-field {
            color: #141417;
            padding: 3px;
          }
          &::-webkit-datetime-edit-day-field {
            color: #141417;
            padding: 3px;
          }
          &::-webkit-datetime-edit-year-field {
            color: #141417;
            padding: 3px;
          }
          &:first-child {
            margin-bottom: 5px;
          }
        }
      }
      .codeDiscount {
        width: 125px;
        display: flex;
        align-items: center;
        .dropdown {
          width: fit-content;
          margin-right: $sd-spacing-md;
          .select-wrapper {
            .select-list {
              width: 50px;
              font-size: 14px;
              overflow-x: hidden;
              .select-list-item {
                padding: $sd-spacing-xxs $sd-spacing-sm;
              }
            }
            button.select-header {
              .select-header-title {
                font-size: 14px;
                padding-left: 0;
              }
              img {
                left: 16px;
              }
            }
          }
        }
        input {
          width: 35px;
        }
        .tag {
          margin-right: 10px;
        }
      }
      .codeItemTags {
        width: 125px;
      }
      .codeRedeemed {
        width: 125px;
        .codeSpendings,
        .codeLimit {
          display: flex;
        }
        input {
          width: 35px;
          margin-right: 8px;
        }
        .dropdown {
          width: fit-content;
          .select-wrapper {
            .select-list {
              width: 100px;
              font-size: 14px;
              .select-list-item {
                padding: $sd-spacing-xxs $sd-spacing-sm;
              }
            }
            button.select-header {
              .select-header-title {
                font-size: 12px;
                padding-left: 0;
                white-space: nowrap;
              }
              img {
                position: unset;
                width: 14px;
                margin-left: 3px;
              }
            }
          }
        }
      }
      .codeRedeemPerUser {
        width: 125px;
        input {
          width: 45px;
        }
      }
      .codeServices {
        width: 195px;
        .addServiceType {
          display: inline-block;
          .dropdownTagHeader {
            font-size: 12px;
            color: $sd-green-primary;
            img {
              display: none;
            }
          }
          .dropdownTagPopper {
            width: 125px;
          }
          width: fit-content;
          padding: 0 5px;
          background-color: transparent;
          color: $sd-green-primary;
          border: 1px solid $sd-green-primary;
          border-radius: 4px;
        }
      }
    }
    .codeActions {
      width: 135px;
      font-family: PlutoSans;
      margin-left: $sd-spacing-sm;
      button {
        font-size: 14px;
        height: unset;
        background-color: transparent;
        padding: 0;
        margin-bottom: 0;
        &:disabled {
          opacity: 0.6;
          font-size: 12px;
        }
        &.edit {
          color: $sd-green-primary;
        }
        &.delete {
          color: #ff6b6b;
        }
      }
    }
  }
  .codeServiceItem {
    padding: 2px 8px;
    border-radius: 4px;
    background-color: rgba(195, 217, 229, 0.6);
    font-size: 12px;
    text-align: center;
    color: #6b7d88;
    cursor: pointer;
  }
  .codeListFilter {
    display: flex;
    justify-content: space-between;
    padding: $sd-spacing-md;
    .filterSearch {
      width: 250px;
      .input {
        margin-bottom: 0;
        input {
          font-size: 16px;
        }
      }
      color: #85858b;
      margin-right: $sd-spacing-sm;
      position: relative;
      .filterSearchIcon {
        background-color: transparent;
        padding: 12px;
        border-radius: 0;
        position: absolute;
        top: 12px;
        right: $sd-spacing-xs;
        width: 25px;
        height: 25px;
        z-index: 2;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMS4zNzQ4IDkuMjYyNjFMMTMuNzQzNCAxMS42MzEyQzE0LjA4NTUgMTEuOTczMyAxNC4wODU1IDEyLjUyNjMgMTMuNzQzNCAxMi44Njg0TDEyLjg2ODQgMTMuNzQzNEMxMi42OTc4IDEzLjkxNCAxMi40NzM4IDEzLjk5OTggMTIuMjQ5OCAxMy45OTk4QzEyLjAyNTggMTMuOTk5OCAxMS44MDE4IDEzLjkxNCAxMS42MzEyIDEzLjc0MzRMOS4yNjI2MSAxMS4zNzQ4QzguMzQzODcgMTEuOTI2OSA3LjI3Mjg5IDEyLjI0OTggNi4xMjQ5IDEyLjI0OThDMi43NDgzMyAxMi4yNDk4IDAgOS41MDIzNSAwIDYuMTI0OUMwIDIuNzQ3NDYgMi43NDgzMyAwIDYuMTI0OSAwQzkuNTAxNDggMCAxMi4yNDk4IDIuNzQ3NDYgMTIuMjQ5OCA2LjEyNDlDMTIuMjQ5OCA3LjI3MjAxIDExLjkyNjEgOC4zNDM4NyAxMS4zNzQ4IDkuMjYyNjFaTTEuNzUgNi4xMjQ5M0MxLjc1IDguNTQwNzcgMy43MDkwOSAxMC40OTk5IDYuMTI0OTMgMTAuNDk5OUM4LjU0MDc3IDEwLjQ5OTkgMTAuNDk5OSA4LjU0MDc3IDEwLjQ5OTkgNi4xMjQ5M0MxMC40OTk5IDMuNzA5MDkgOC41NDA3NyAxLjc1IDYuMTI0OTMgMS43NUMzLjcwOTA5IDEuNzUgMS43NSAzLjcwOTA5IDEuNzUgNi4xMjQ5M1oiIGZpbGw9IiM2MkM5RTMiLz4KPC9zdmc+Cg==");
      }
    }
    .filterExpired {
      flex-grow: 1;
      font-size: 14px;
      color: #85858b;
      display: flex;
      align-items: center;
      .checkmark {
        top: -6px;
        left: 8px;
      }
    }
    .filterService {
      display: flex;
      align-items: center;
      .filterServiceDropdown {
        margin-left: $sd-spacing-xs;
        .dropdownTagPopper {
          width: 135px;
        }
      }
    }
  }
  .buttonCreate {
    width: 275px;
    margin-top: $sd-spacing-md;
  }
  .toggleSwitch {
    display: inline-block;
    .switch {
      cursor: pointer;
      position: relative;
      width: 47px;
      height: 24px;
      border-radius: 14px;
      transition: background-color 100ms ease-out;
      z-index: 1;
      box-shadow: 2px 2px 8px 0 #a7b1bf, -5px -3px 8px 0 #ffffff, inset 2.5px 2.5px 13px 0 #d9e2e7;
      background-color: #f9fdff;
      margin-left: 16px;
      .toggle-button {
        position: relative;
        width: 25px;
        height: 25px;
        border: double 2px transparent;
        border-radius: 80px;
        cursor: pointer;
        &.position-right {
          transform: translateX(22px);
          background-image: linear-gradient(white, white), radial-gradient(circle at top left, #66f1e6, #4bc9ba);
          background-origin: border-box;
          background-clip: content-box, border-box;
        }
        &.position-left {
          transform: translateX(-2px);
          background-image: linear-gradient(white, white), radial-gradient(circle at top left, #f16e66, #c94b4b);
          background-origin: border-box;
          background-clip: content-box, border-box;
        }
      }
    }
  }

  .formRow {
    display: flex;
    font-size: 14px;
    color: #85858b;
    padding: $sd-spacing-md 0 0 $sd-spacing-md;
    align-items: center;
    .sm.limitValue.hidden {
      display: none;
    }
    .select-list {
      overflow-x: hidden;
      .select-list-item {
        color: gray;
      }
    }
    .input {
      margin-bottom: 0;
      input {
        box-shadow: 2px 2px 2px 0 rgba(209, 217, 230, 0.4);
        -webkit-box-shadow: 2px 2px 2px 0 rgba(209, 217, 230, 0.4);
        border: solid 1px #d1e4ec;
        color: #85858b;
      }
    }
    .select-wrapper {
      margin-bottom: 0;
      .select-header {
        box-shadow: 2px 2px 2px 0 rgba(209, 217, 230, 0.4);
        -webkit-box-shadow: 2px 2px 2px 0 rgba(209, 217, 230, 0.4);
        border: solid 1px #d1e4ec;
        font-size: 16px;
        color: #85858b;
      }
    }
    .formItem:nth-child(1) {
      width: 310px;
      margin-right: $sd-spacing-sm;
    }
    .formItem.fullWidth {
      width: 100%;
    }
    .formItem:nth-child(2) {
      margin-right: $sd-spacing-sm;
      .dateContainer {
        border-radius: 10px;
        box-shadow: 2px 2px 2px 0 rgba(209, 217, 230, 0.4);
        border: solid 1px #d1e4ec;
        font-family: "HelveticaNeueLTProRoman";
        font-size: 16px;
        span {
          color: #85858b;
        }
      }
    }
    .formItem:nth-child(3) {
      flex-grow: 1;
      display: flex;
      align-items: center;
    }
    .formItemFlex {
      display: flex;
      div:nth-child(1) {
        width: 130px;
        margin-right: $sd-spacing-sm;
      }
    }
    .formItemServices {
      display: flex;
      .services {
        margin-right: $sd-spacing-sm;
        margin-bottom: 0;
        &.primary {
          box-shadow: none;
          -webkit-box-shadow: none;
        }
      }
    }

    .codeMethodList {
      padding-left: 16px;
      display: inline-block;
      .codeMethodItem {
        cursor: pointer;
        &:not(:last-child) {
          margin-bottom: 16px;
        }
        > span {
          margin-left: 32px;
        }
      }
    }
  }
  .header {
    display: grid;
    grid-template-columns: 8fr auto auto auto 0.5fr;
    box-shadow: inset 3px -2px 12px rgba(221, 224, 230, 0.55);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    ul {
      list-style-type: none;
      display: flex;
      padding: 0;
      justify-self: start;
      li {
        padding: $sd-spacing-sm $sd-spacing-md;
        text-transform: uppercase;
        cursor: pointer;

        &:first-child {
          border-top-left-radius: 20px;
        }

        &.active {
          background-color: $sd-background-light;
          height: 100%;
          box-shadow: 0px -10px 12px rgba(221, 224, 230, 0.55);

          button {
            font-weight: 500;
            color: #35b0ad;
            cursor: pointer;
          }
        }

        &.inactive {
          button {
            font-weight: normal;
            color: $text-color-captions;
            cursor: pointer;
          }
        }

        button {
          background-color: transparent;
          padding: 0;
          margin: 0;
          font-family: "PlutoSans";
          font-size: $font-size-xs;
          font-weight: normal;
          text-transform: uppercase;
          height: 100%;
          color: $sd-grey-dark;
          cursor: pointer;
        }
      }
    }
  }
}
