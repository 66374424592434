.notificationModal {
  width: 350px !important;
  .content {
    padding: 25px;
    width: 350px;

    .close {
      position: absolute;
      top: 19px;
      right: 19px;
      height: 14px;
      width: 14px;
      cursor: pointer;
    }

    .title {
      font-family: "Open Sans";
      font-size: 18px;
      font-weight: 600;
      line-height: 1.22;
      text-align: left;
      color: #56738f;
      margin-bottom: 12px;
    }

    .description {
      font-family: "Open Sans";
      font-size: 15px;
      text-align: left;
      color: #56738f;
      margin-bottom: 17px;
      span {
        font-weight: 600;
      }
      svg {
        display: inline;
        vertical-align: middle;
        margin-right: 12px;
      }
    }

    .actionButtons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      button {
        padding: 0 12px;
        border-radius: 6px;
      }
      .cancelUM {
        background: transparent;
        font-size: 12px;
        font-weight: 600;
        color: #00c3b5;
        padding: 0;
        margin: 0;
        height: auto;
      }
      .primary {
        height: 34px;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.29;
        margin: 0;
      }
    }
  }
}
