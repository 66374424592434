.timeblocks {
  height: 100%;
  position: relative;
  display: inline-block;

  .timeBlock {
    position: absolute;
    cursor: pointer;
    height: 100%;
    border-left: solid 1px #ffffff80;

    &.roster {
      color: $e-roster-roster-text-color;
      svg {
        path {
          fill: $e-roster-roster-text-color;
        }
      }
    }

    &.video {
      color: $e-roster-video-text-color;
      svg {
        path {
          fill: $e-roster-video-text-color;
        }
      }
    }

    &.visit {
      color: $e-roster-visit-text-color;
      svg {
        path {
          fill: $e-roster-visit-text-color;
        }
      }
    }

    &.clinic {
      color: $e-roster-clinic-text-color;
      svg {
        path {
          fill: $e-roster-clinic-text-color;
        }
      }
    }

    &.delivery {
      color: $e-roster-delivery-text-color;
      svg {
        path {
          fill: $e-roster-delivery-text-color;
        }
      }
    }

    .timeBlockWrapper {
      width: 100%;
      display: flex;
      overflow: hidden;
      pointer-events: none;

      .serviceIcons {
        padding: 4px;
        display: flex;
        align-items: center;

        svg {
          margin-right: 4px;
        }
      }

      .title {
        padding: 4px;
        margin: 0 4px;
        &.ellipsis {
          @include text-ellipsis();
        }
      }

      .duration {
        padding: 4px;
        margin-left: auto;
        text-align: right;
        white-space: nowrap;
      }
    }
  }
}
