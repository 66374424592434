.cashCollectionUM {
  .content {
    .formContainer {
      display: grid;
      padding: $sd-spacing-sm;
      grid-template-columns: 1fr 1fr 1fr auto;
      gap: $sd-spacing-sm;

      .inputUM {
        display: inline-flex;
      }
      .cashForm {
        grid-column: span 4;
        align-items: center;
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type="number"] {
        -moz-appearance: textfield;
      }
      hr {
        grid-column: span 4;
        border: none;
        border-top: 1px solid #eee;
        margin: 0 -16px;
      }
      .caption {
        grid-column: span 4;
        padding-left: 36px;
        color: #6c6c6c;
      }
      .provider {
        display: flex;
        grid-column: span 4;
        align-items: center;
        .providerInfo {
          display: flex;
          align-items: center;
          .avatarUM {
            margin-right: 8px;
          }
        }
        svg {
          margin-right: 12px;
        }
      }
      .collectedTime {
        display: flex;
        grid-column: span 4;
        align-items: center;

        .icon {
          margin-right: 16px;
        }
      }
    }
  }
  .footerUM {
    .footerLine {
      margin: 0px;
      border: none;
      border-top: 1px solid #eee;
    }
    .actions {
      display: flex;
      justify-content: space-between;

      .leftActions {
        display: flex;
        justify-content: flex-start;
        align-self: center;
        margin: 16px;
      }
      .rightActions {
        display: flex;
        margin: 16px;
        justify-content: flex-end;
        .loader {
          display: flex;
          flex-direction: row-reverse;
        }
        .buttonUM {
          display: inline-flex;
        }
      }
    }

    .linkGreen,
    .linkRed {
      padding: 8px 12px;
    }
  }
}
