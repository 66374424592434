.destinationListUM {
  .content {
    display: flex;
    flex-direction: column;
    .search {
      margin: 0 $sd-spacing-sm $sd-spacing-sm $sd-spacing-sm;
      svg {
        path {
          fill: #ff5c8e;
        }
      }
    }
    .placeList {
      text-align: center;
      overflow-y: scroll;
      &.disableScroll {
        overflow: hidden;
      }
      @include scrollbar();
      .loader div {
        margin: $sd-spacing-xs auto;
      }
    }
    .placeItem {
      text-align: left;
      padding: $sd-spacing-xs $sd-spacing-sm $sd-spacing-xs $sd-spacing-xl;
      cursor: pointer;
      &:hover {
        background-color: #f1f3f4;
      }
      .placeTitle {
        color: #4f657a;
      }
      .placeOneLine {
        font-size: $font-size-jarvis-xs;
        color: $text-color-captions;
      }
    }
    hr {
      border: none;
      border-top: 1px solid #f1f3f4;
      margin: 0;
    }
  }
}
