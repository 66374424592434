.taskCreate {
  @import "shared/taskTitleTabs";

  textarea {
    border-radius: 0;
  }

  .fieldSchedule {
    .react-datepicker-wrapper {
      width: 50%;
    }
    .MuiFormControl-root {
      background-color: #cee9ff;
      border-radius: 6px;
    }
  }

  .fieldNotes .MuiFormHelperText-root {
    text-align: right;
  }

  .noBorder fieldset {
    border: none;
    border-width: 0;
  }

  .MuiTextField-root {
    input,
    textarea {
      font-size: 12px;
    }

    .MuiFormHelperText-root {
      font-size: 10px;
    }
  }

  .MuiAutocomplete-popper {
    .MuiListItemText-primary,
    .MuiAutocomplete-noOptions,
    .MuiAutocomplete-loading {
      font-size: 12px;
    }

    .MuiListItemText-secondary {
      font-size: 10px;
    }
  }
}
