.Toaster__message {
  margin: $sd-spacing-sm;
  .Toaster__alert,
  .toasterConfirm {
    overflow: hidden;
    width: $sd-spacing-lg * 10;
    position: relative;
    border-radius: $sd-border-radius-md;
    display: flex;
    box-shadow: rgba(52, 58, 64, 0.15) 0px 1px 10px 0px, rgba(52, 58, 64, 0.1) 0px 6px 12px 0px,
      rgba(52, 58, 64, 0.12) 0px 6px 15px -2px;
    font-family: "OpenSansSemiBold";
    font-size: $font-size-lg;
    letter-spacing: normal;
    color: white;
    text-align: center;
    z-index: 70001;

    .default {
      width: 100%;
      padding: $sd-spacing-sm $sd-spacing-xxl $sd-spacing-sm $sd-spacing-sm;
      background-color: $sd-green-success;
    }

    .error {
      width: 100%;
      padding: $sd-spacing-sm $sd-spacing-xl $sd-spacing-sm $sd-spacing-sm;
      background-color: $sd-red;
    }
    .confirm,
    .cancel {
      background-color: white;
      color: $sd-green-success;
    }
    .Toaster__alert_close {
      padding: $sd-spacing-sm;
      outline: none;
      cursor: pointer;
      background-color: transparent;
      position: absolute;
      top: $sd-spacing-xxs;
      right: $sd-spacing-xxs;
      border: 0;
      -webkit-appearance: none;
      font-size: $font-size-lg;
      font-weight: 700;
      line-height: 1;
      text-shadow: 0 1px 0 #fff;
      width: auto;
      height: auto;
      color: white;
    }
  }

  .toasterConfirm {
    margin-top: 45%;
    width: $sd-spacing-xxl * 7;
    background-color: #f8fcfe;
    &.complete {
      margin-top: 25%;
    }
    .completeRequest {
      width: 100%;
      padding: $sd-spacing-xxl;
      color: #141417;
      font-size: 18px;
    }
    .completeRequestButton {
      margin-right: $sd-spacing-lg;
    }
    .confirm,
    .cancel {
      background-color: $sd-background-light;
      width: 100%;
      display: grid;
      grid-template-rows: 2fr 1fr;
      align-items: center;
      justify-content: center;

      span {
        padding: $sd-spacing-md;
      }

      .actionButtons {
        button {
          margin-right: $sd-spacing-md;

          &.error {
            width: auto;
            line-height: 40px;

            padding: 0 $sd-spacing-md;
          }
        }
      }
    }

    .cancel {
      .callback {
        background-color: $sd-red;
      }
    }
  }
}

.Toaster__message-wrapper {
  transform: none !important;
}

.Toaster__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.5;
  z-index: -1;
  width: 100vw;
  height: 100vw;
}
