.tag {
  padding: 2px 8px;
  cursor: pointer;
  border-radius: 4px;
  margin: $sd-spacing-xxs;
  background-color: rgba(195, 217, 229, 0.6);
  height: 22px;
  font-size: 12px;
  text-align: center;
  color: #6b7d88;
  display: inline-block;
  &.disabled {
    cursor: default;
  }
  &.secondary {
    background-color: transparent;
    color: $sd-green-primary;
    border: 1px solid $sd-green-primary;
  }
}
