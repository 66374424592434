@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Mulish:300,400,500,600,700&display=swap");

@import "./oldDesignAssets/all.scss";
@import "~react-datepicker/dist/react-datepicker.css";
@import "reset";
@import "variables";
@import "mixins";
@import "components/index";
@import "global/index";
@import "universalModal/index";
@import "universalModal/pages/index";
@import "global/text";
@import "pages";
@import "overrides";

html,
body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", "PlutoSans", "Helvetica";
  font-weight: 400;
  color: black;
  background-color: #ffffff;

  #root {
    z-index: 0;
    position: relative;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
