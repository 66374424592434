.btnCancel {
  margin-left: 8px;
  width: 94px;
  color: #00c3b5;
  border-color: #00c3b5;
  border-radius: 6px;
}

.btnSave {
  width: 94px;
  color: #ffffff;
  background: #00c3b5;
  border-radius: 6px;

  &:hover {
    background: #00c3b5;
  }
}
