.taskForm {
  width: max-content;
  padding: 16px;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: normal;
  z-index: 6;

  .highlight {
    position: absolute;
    background: #e8e8e8;
    border: 1px solid #c0c0c0;
    box-sizing: border-box;
    border-radius: 4px;
  }

  .header {
    display: flex;
    align-items: center;

    .title {
      flex-grow: 1;
    }

    .iconWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      border-radius: 4px;

      &:hover {
        background: #e7edf4;
      }
    }

    .iconButton {
      cursor: pointer;
    }

    .iconGap {
      margin-left: 8px;
    }
  }

  .taskSelect {
    display: flex;
    margin-top: 16px;
    margin-left: -2px;
    align-items: center;
    column-gap: 6px;

    @mixin taskTags {
      .color {
        width: 17px;
        height: 17px;
        margin-right: 8px;
        border-radius: 4px;
        order: 0;
        flex-grow: 0;
      }
    }

    .dropdown {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 158px;
      padding: 4px;
      background: #ffffff;
      border: 1.5px solid #eeeeee;
      box-sizing: border-box;
      border-radius: 6px;

      .select-wrapper {
        width: 100%;

        .select-header {
          display: flex;
          justify-content: space-between;

          .select-header-title {
            display: flex;
            font-family: "Open Sans";
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            align-items: flex-end;
            padding-left: unset;
            color: #000000;

            @include taskTags();
          }

          img {
            position: unset;
            margin-right: unset;
            width: unset;
          }
        }

        .select-list {
          display: flex;
          top: 32px;
          left: -4px;
          width: max-content;
          min-width: 100px;
          flex-direction: column;
          align-items: flex-start;
          padding: 8px 0px;
          background: #ffffff;
          box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
          border-radius: 4px;
          border: unset;

          .select-scroll-list {
            width: 100%;
          }

          .select-list-item {
            display: flex;
            font-family: "Open Sans";
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            flex-direction: row;
            align-items: center;
            color: #000000;
            padding: 8px 40px 8px 8px;

            @include taskTags();
          }
        }
      }
    }

    svg {
      margin-right: 4px;
      height: 20px;
      width: 20px;
    }
  }

  .durationSelect {
    display: flex;
    margin-top: 16px;
    align-items: center;
    column-gap: 8px;

    input {
      padding: 4px;
      width: 65px;
    }

    .timePicker {
      svg {
        top: 10px;
        height: 6px;
        width: 10px;
        path {
          fill: white;
        }
      }
    }

    .clockIcon {
      margin-right: 4px;
    }
  }

  .note {
    .icon {
      margin-right: 2px;

      svg {
        margin-top: 2px;
        height: 20px;
        width: 20px;
      }
    }
  }

  .footer {
    margin-top: 16px;
    text-align: right;

    .confirmButton {
      margin: 0;
      padding: 0;
      height: auto;
      background-color: inherit;
      color: #15bfcc;
      font-size: 12px;
      font-weight: 600;

      &.editMode {
        padding: 8px;
        font-size: 14px;
        background-color: #00c3b5;
        color: white;
        border-radius: 6px;
      }
    }
  }

  hr {
    margin: 12px 0px 8px 0px;
    background-color: #eeeeee;
    height: 1px;
    border: 0;
  }

  svg {
    height: 16px;
    width: 16px;

    path {
      fill: #9bb0c4;
    }
  }
}
