.collapsibleRow {
  cursor: pointer;

  .collapsibleHeader {
    padding-left: 0;
    font-size: 16px;
    font-weight: 600;
    color: #48a4a1;
  }
}
