.taskEditFormDialog {
  @import "shared/taskTitleTabs";

  input,
  textarea {
    font-size: 12px;
  }

  .taskStatus {
    font-weight: 700;
    font-size: 12px;

    &.pending {
      color: #778cad;
      background-color: #dfecf0;
    }
    &.completed {
      color: #2aaab4;
      background-color: #cef7fa;
    }
    &.cancelled {
      color: #e4104f;
      background-color: rgba(228, 16, 79, 0.15);
    }
  }

  .taskId,
  .taskAddress {
    font-size: 12px;
  }

  .priority {
    .MuiFormControlLabel-label {
      font-size: 14px;
    }
  }

  .btnSave {
    background: #d9f5f3;
    color: #00c3b5;
    border-radius: 6px;
    font-weight: 600;

    &:hover {
      background: #d9f5f3;
      color: #00c3b5;
    }
  }
}
