.universalModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  font-family: "Open Sans";
  font-size: $font-size-jarvis-sm;
  background-color: rgba(32, 32, 32, 0.2);

  hr {
    background-color: #eeeeee;
    height: 1px;
    border: 0;
  }

  .modalContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    background-color: #ffffff;
    width: 600px;
    box-shadow: 3px -2px 12px 0 rgba(221, 224, 230, 0.54), 10px 10px 20px 0 rgba(228, 235, 246, 0.4),
      -10px -10px 20px 0 rgba(232, 233, 236, 0.55);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    min-width: 520px;
    max-height: calc(100vh - 120px);
    height: 750px;

    > .content {
      margin-bottom: auto;
      overflow: visible;
      overflow-y: auto;
      flex-grow: 1;
      @include scrollbar();
      &.trackingMap > div > div {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }

  .display-block {
    display: block;
  }

  .display-none {
    display: none;
  }
}
