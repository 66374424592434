.cancellationUM {
  .content {
    .inputUM {
      padding: 8px 16px 0px;
      margin-left: 42px;
      .inputGroup {
        .inputContainer {
          .inputInfo {
            right: 0px;
          }
        }
      }
    }
  }
  .radioButtonContainerUM {
    gap: unset;
    .radioButtonUM {
      padding: 10px 18px;
      height: 50px;
      align-items: center;
      grid-column-gap: 27px;
      cursor: pointer;

      &:hover,
      &.selected {
        background-color: #e5f9f8;
      }
      .icon {
        margin: auto;
      }
    }
  }
}
