.logsDialog {
  padding-bottom: 50px;
  .MuiTableRow-head {
    th {
      font-size: 12px;
      color: #979797;
    }
  }

  .MuiTableBody-root {
    tr * {
      font-size: 12px;
    }
  }

  .date {
    max-width: 100px;
    .dateValue {
      display: block;
      color: #979797;
    }
  }

  .user {
    max-width: 100px;
    font-weight: bold;
    @include text-ellipsis();
  }

  .action {
    max-width: 180px;
    color: #979797;
  }
}
