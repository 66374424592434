.dateFilter {
  .datePicker {
    position: absolute;
    padding-top: 8px;

    .react-datepicker {
      font-size: 12px;
      box-shadow: 0px 1px 10px 0px #00000033;
      border: none;
      z-index: 2;
    }
    .react-datepicker__header {
      background-color: white;
      border-bottom: none;
    }
    .react-datepicker__current-month {
      margin-bottom: $sd-spacing-xs;
      padding-left: $sd-spacing-sm;
      color: #172733;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
    }
    button.react-datepicker__navigation {
      top: 8px;
      border-radius: 0;
      border: 6px solid transparent;
      &.react-datepicker__navigation--previous {
        left: 140px;
      }
    }

    .react-datepicker__month {
      margin-top: 0;
    }
    .react-datepicker__day-name,
    .react-datepicker__day {
      width: $sd-spacing-md;
      height: $sd-spacing-md;
      line-height: $sd-spacing-md;
      font-size: $font-size-jarvis-xs;
      font-weight: 500;
    }
    .react-datepicker__day-name {
      color: #6f7a81;
    }
    .react-datepicker__day {
      color: #172733;

      .dayNumber {
        display: inline-block;
        width: 100%;
      }
    }
    .react-datepicker__day--selected {
      background-color: #c6ebe8;
      color: #172733;
    }
    .react-datepicker__day--today {
      background-color: #00c3b5;
      color: white;
      border-radius: 6px;
    }
    .holiday {
      color: red;
    }
    .react-datepicker__day--keyboard-selected {
      background-color: white;
    }
  }
}
