form,
.forms {
  display: flex;
  flex-direction: column;

  &.marginTop60 {
    margin-top: $sd-spacing-lg * 2;
  }

  h2,
  h3 {
    margin-bottom: $sd-spacing-xs;
    font-family: "PlutoSans";
    font-size: $font-size-md;
    font-weight: 300;
    line-height: 1.5;
    letter-spacing: normal;
    color: $sd-textcolor-black;
    text-transform: uppercase;
  }

  h3 {
    font-size: $font-size-sm;
  }

  .buttonsFooterForm {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    padding: 0 $sd-spacing-lg 0 $sd-spacing-sm;

    .footerRight {
      justify-content: flex-end;
      z-index: 1;
    }
  }
}

.input {
  margin-bottom: $sd-spacing-sm;
  .react-datepicker-wrapper {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;

    .react-datepicker__input-container {
      position: relative;
      width: 100%;
      height: 100%;
      input {
        height: $sd-spacing-xl;
        font-size: $font-size-sm;
        cursor: pointer;

        &[disabled] {
          cursor: default;
        }
      }
    }
  }
  .react-datepicker__tab-loop {
    position: absolute;
    .react-datepicker-popper {
      z-index: 3;

      .react-datepicker__time-container--with-today-button {
        right: -85px;
        top: -1px;
        height: 320px;
        .react-datepicker__time-list {
          height: 275px;
        }
      }
    }
  }

  textarea {
    width: 100%;
  }
}
