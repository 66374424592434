.dateContainer {
  background-color: white;

  .disabled {
    pointer-events: none;
    opacity: 0.54;
  }
}

#dateRangePicker {
  display: none;
  position: relative;
  font-family: Helvetica;

  &.show {
    display: block;
  }
  &.popperRight {
    .dateRangeBackground,
    .dateRangeBackgroundWhite,
    .dateRangeContainer {
      right: 0;
      border-radius: 12px 0 12px 12px;
    }
  }
  &.popperLeft {
    .dateRangeBackground,
    .dateRangeBackgroundWhite,
    .dateRangeContainer {
      left: 0;
      border-radius: 0 12px 12px 12px;
    }
  }
  .dateRangeBackground {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.13;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    background-image: linear-gradient(154deg, #f4f4f4 34%, #35b0ad 89%);
  }
  .dateRangeBackgroundWhite {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #f9fdff;
  }
  .dateRangeContainer {
    display: flex;
    position: absolute;
    box-shadow: 10px 10px 20px 0 rgba(209, 217, 230, 0.4);
    border-color: transparent;
  }
  .react-datepicker {
    background-color: transparent;
    border: none;
    .react-datepicker__navigation {
      width: 20px;
      height: 20px;
      border: none;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyMiIgdmlld0JveD0iMCAwIDIyIDIyIj4KICAgIDxwYXRoIGZpbGw9IiM2MkM5RTMiIGQ9Ik0xNi42NjcgNy45MTJsLS42NzMtLjYxQzE1Ljc2IDcuMSAxNS40ODcgNyAxNS4xNzYgN2MtLjMxOCAwLS41ODcuMS0uODA5LjMwMUwxMCAxMS4yNjEgNS42MzMgNy4zYy0uMjIyLS4yLS40OTEtLjMtLjgwOS0uMy0uMzExIDAtLjU4NC4xLS44MTguM2wtLjY2NS42MTJjLS4yMjcuMjA2LS4zNDEuNDUzLS4zNDEuNzQgMCAuMjk0LjExNC41MzkuMzQxLjczNGw1Ljg1IDUuMzAzYy4yMTYuMjA3LjQ4NS4zMS44MDkuMzEuMzE4IDAgLjU5LS4xMDMuODE4LS4zMWw1Ljg1LTUuMzAzYy4yMjEtLjIwMS4zMzItLjQ0NS4zMzItLjczMyAwLS4yODMtLjExLS41My0uMzMzLS43NDJ6Ii8+Cjwvc3ZnPgo=);
      .react-datepicker__navigation-icon {
        display: none;
      }
    }
    .react-datepicker__navigation--previous {
      top: 0px;
      right: 60px;
      left: unset;
      transform: rotate(180deg);
    }
    .react-datepicker__navigation--next {
      top: 15px;
      right: 58px;
    }
  }
  .dateRangePickerStart {
    margin-right: $sd-spacing-lg;
  }
  .dateRangePickerStart .react-datepicker__header {
    background: linear-gradient(to right, #62cbc9 0%, transparent 75%) left bottom transparent no-repeat;
    background-size: 100% 1px;
  }
  .dateRangePickerEnd .react-datepicker__header {
    background: linear-gradient(to left, #62cbc9 0%, transparent 75%) left bottom transparent no-repeat;
    background-size: 100% 1px;
  }
  .react-datepicker__navigation {
    margin-top: 12px;
  }
  .react-datepicker__current-month {
    font-size: 14px;
    opacity: 0.6;
    letter-spacing: normal;
    margin-bottom: 20px;
  }
  .react-datepicker__header {
    background-color: transparent;
    color: #200000;
    padding-top: 20px;
    border-bottom: none;
  }
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range {
    color: #200000;
    background: linear-gradient(to left, rgba(195, 248, 237, 1) 0%, rgba(195, 248, 237, 1) 100%) left center transparent
      no-repeat;
    background-size: 100% 25px;
  }
  .react-datepicker__day--keyboard-selected:not(.react-datepicker__day--in-range) {
    background-color: inherit;
    color: inherit;
  }
  .react-datepicker__day--selected.react-datepicker__day--range-start.react-datepicker__day--in-range,
  .react-datepicker__day--selected.react-datepicker__day--in-selecting-range,
  .react-datepicker__day--range-start {
    background-position: left center, 10px center;
  }
  .react-datepicker__day--range-end {
    background-position: left center, -10px center;
    background-size: contain, 100% 25px;
  }
  .react-datepicker__day--selected.react-datepicker__day--range-start.react-datepicker__day--in-range:not(
      .react-datepicker__day--range-end
    ),
  .react-datepicker__day--selected.react-datepicker__day--in-selecting-range,
  .react-datepicker__day--range-end {
    background-color: transparent;
    color: white;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMzJweCIgaGVpZ2h0PSIzM3B4IiB2aWV3Qm94PSIwIDAgMzIgMzMiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+T3ZhbDwvdGl0bGU+CiAgICA8ZyBpZD0iU3ltYm9scyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IkNhbGFuZGFyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNjkuMDAwMDAwLCAtMjAzLjAwMDAwMCkiIGZpbGw9IiM2MkUzQzMiPgogICAgICAgICAgICA8ZyBpZD0iR3JvdXAtNSI+CiAgICAgICAgICAgICAgICA8ZyBpZD0iR3JvdXAtNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNjkuMDAwMDAwLCAyMDMuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgPHBhdGggZD0iTTIwLjUsMzIuNDE4NDY5MiBDMjkuMzM2NTU2LDMyLjQxODQ2OTIgMzMuNzIxNDk5LDIwLjk1MzQ4IDMwLDEyLjM1NDU5NTQgQzI2LjI3ODUwMSwzLjc1NTcxMDY2IDI0LjgzNjU1NiwwIDE2LDAgQzcuMTYzNDQ0LDAgLTIuMDQyODEwMzdlLTEzLDcuMTYzNDQ0IC0yLjA0MjgxMDM3ZS0xMywxNiBDLTIuMDQyODEwMzdlLTEzLDI0LjgzNjU1NiAxMS42NjM0NDQsMzIuNDE4NDY5MiAyMC41LDMyLjQxODQ2OTIgWiIgaWQ9Ik92YWwiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+),
      linear-gradient(to left, rgba(195, 248, 237, 1) 0%, rgba(195, 248, 237, 1) 100%);
    background-size: contain, 100% 25px;
    background-repeat: no-repeat;
  }
  .react-datepicker__day--selected {
    background-color: transparent;
    color: white;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMzJweCIgaGVpZ2h0PSIzM3B4IiB2aWV3Qm94PSIwIDAgMzIgMzMiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+T3ZhbDwvdGl0bGU+CiAgICA8ZyBpZD0iU3ltYm9scyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IkNhbGFuZGFyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNjkuMDAwMDAwLCAtMjAzLjAwMDAwMCkiIGZpbGw9IiM2MkUzQzMiPgogICAgICAgICAgICA8ZyBpZD0iR3JvdXAtNSI+CiAgICAgICAgICAgICAgICA8ZyBpZD0iR3JvdXAtNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNjkuMDAwMDAwLCAyMDMuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgPHBhdGggZD0iTTIwLjUsMzIuNDE4NDY5MiBDMjkuMzM2NTU2LDMyLjQxODQ2OTIgMzMuNzIxNDk5LDIwLjk1MzQ4IDMwLDEyLjM1NDU5NTQgQzI2LjI3ODUwMSwzLjc1NTcxMDY2IDI0LjgzNjU1NiwwIDE2LDAgQzcuMTYzNDQ0LDAgLTIuMDQyODEwMzdlLTEzLDcuMTYzNDQ0IC0yLjA0MjgxMDM3ZS0xMywxNiBDLTIuMDQyODEwMzdlLTEzLDI0LjgzNjU1NiAxMS42NjM0NDQsMzIuNDE4NDY5MiAyMC41LDMyLjQxODQ2OTIgWiIgaWQ9Ik92YWwiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+);
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: center;
  }
  .react-datepicker__day--outside-month {
    color: transparent;
    pointer-events: none;
    &.react-datepicker__day--range-start,
    &.react-datepicker__day--range-end,
    &.react-datepicker__day--in-range,
    &.react-datepicker__day--in-selecting-range,
    &.react-datepicker__day--keyboard-selected,
    &.react-datepicker__day--selected {
      background-color: transparent;
      background: none;
    }
  }
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__quarter-text--in-selecting-range:hover,
  .react-datepicker__quarter-text--in-range:hover,
  .react-datepicker__year-text--selected:hover,
  .react-datepicker__year-text--in-selecting-range:hover,
  .react-datepicker__year-text--in-range:hover {
    background-color: inherit;
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    margin: 0;
    padding: 5px 0;
    width: 36px;
    display: inline-block;
    text-align: center;
  }
}
