.input {
  &::before {
    height: unset;
  }
  input {
    box-shadow: none;
    border: solid 2px #e7edf4;
    &::placeholder {
      color: #979797;
    }
    background: transparent;
    border: solid 1.5px #e7edf4;
  }
}
