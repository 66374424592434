.stepper {
  display: grid;
  grid-template-columns: 1.2fr 2fr;
  height: 100%;
  .left {
    display: flex;
    flex-direction: column;
    height: 100%;
    border-right: 1px solid $grey-primary;
    background-color: $sd-background-light;
    padding-top: $sd-spacing-lg;
    border-bottom-left-radius: $sd-border-radius-lg;

    .step {
      h5.stepTitle {
        font-family: "HelveticaNeueLTProRoman";
        font-size: $font-size-sm;
        font-weight: normal;
        color: $text-color-captions;
      }
      &.active {
        h5.stepTitle {
          opacity: 0.85;
          color: $sd-textcolor-black;
        }
      }
    }
  }
  .right {
    max-height: 600px;
    position: relative;

    @include scrollbar();

    .closeButton {
      position: absolute;
      right: 0;
      top: 0;
      width: 48px;
      height: 48px;
      float: right;
      background: none;
      border: none;
      outline: none;
      cursor: pointer;
      margin-top: $sd-spacing-sm;
      margin-right: $sd-spacing-sm;
      color: black;
    }

    .forms {
      height: 100%;
      position: relative;
    }

    .footer {
      .previous {
        margin: 0;
        float: left;
        background: none;
        border: none;
        outline: none;
        box-shadow: none;
        color: $sd-green-primary;
      }

      .next {
        margin: 0;
        float: right;
      }
    }
  }
}
