.inputUM {
  display: flex;

  .icon {
    margin-right: 12px;
    svg {
      vertical-align: middle;
    }
  }

  .inputGroup {
    width: 100%;

    .inputContainer {
      position: relative;
      .inputEdit {
        position: absolute;
        right: 12px;
        top: 6px;
        color: #00c3b5;
        display: flex;
        gap: 10px;
        a:hover {
          text-decoration: underline;
        }
      }
      .inputInfo {
        position: absolute;
        right: 12px;
        top: 5px;
        &.withSelect {
          right: 35px;
        }
      }
      .inputCounter {
        color: #979797;
        font-size: 12px;
        position: absolute;
        bottom: 0;
        right: 0;
      }
      input {
        width: 100%;
        border: 1px solid #e7edf4;
        border-radius: 6px;
        height: 30px;
        padding: 0 10px;
        font-family: "Open Sans";
        font-size: 12px;
        background: #ffffff;
        &::placeholder {
          font-family: "Open Sans";
          font-size: 12px;
          color: #a5a5ad;
        }
        &:focus {
          outline: none;
        }
        &:disabled {
          cursor: not-allowed;
        }
      }

      textarea {
        @include scrollbar();
        resize: none;
        width: 100%;
        border: none;
        border-radius: 6px;
        padding: 0 $sd-spacing-xs;
        font-family: "Open Sans";
        font-size: 12px;
        background: #ffffff;
        box-shadow: none;
        color: black;
        margin-top: 4px;
        &::placeholder {
          font-family: "Open Sans";
          font-size: 12px;
          color: #a5a5ad;
        }
        &:focus {
          outline: none;
        }
        &:disabled {
          cursor: not-allowed;
        }
      }
    }

    .text {
      font-family: "Open Sans";
      font-size: 12px;
      color: #4f657a;
    }
  }

  &.noBorder input {
    border: none !important;
    background: transparent !important;
  }
}
