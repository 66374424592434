.addressListUM {
  .addressItem {
    padding: 8px;
    border-radius: 6px;
    cursor: pointer;

    &:hover {
      background-color: #eeeeee;
    }

    .addressOneLine {
      font-size: 10px;
      color: #8496a6;
      @include text-ellipsis;
    }
  }

  .btnAdd {
    background: #d9f5f3;
    color: #00c3b5;
    border-radius: 6px;
    font-weight: 600;

    &:hover {
      background: #d9f5f3;
      color: #00c3b5;
    }
  }

  &.clinicPlaces {
    .search {
      display: flex;
      align-items: center;
      padding: 0 20px 20px 20px;
      .MuiInputBase-input {
        font-size: 12px;
        min-height: 0;
        height: 15px;
        line-height: 15px;
      }
    }

    .list {
      width: 100%;
      height: 100%;
      overflow-y: auto;
    }

    .MuiListItemText-root {
      padding-left: 35px;
    }

    .MuiListItemText-primary {
      font-size: 12px;
    }

    .item {
      &.header {
        .MuiListItemText-primary {
          color: #6c6c6c;
        }
      }
    }

    .MuiListItemText-secondary {
      font-size: 10px;
      color: #6c6c6c;
      max-width: 80%;
    }

    .MuiListItemSecondaryAction-root {
      font-size: 12px;
      color: #6c6c6c;
      padding-right: 15px;
    }

    .loading {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
