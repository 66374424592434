button {
  height: $element-height-md;

  &.sm {
    height: $element-height-sm;
    font-size: $font-size-sm;
  }

  img {
    &.arrow {
      width: $sd-spacing-lg;
      &.right {
        margin-left: $sd-spacing-xxs;
      }
      &.left {
        margin-right: $sd-spacing-xxs;
      }
    }
  }

  font-family: "OpenSansSemiBold", Arial, Helvetica, sans-serif;
  font-size: $font-size-lg;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: center;
  color: white;
  border-radius: $sd-border-radius-md;
  border: none;
  cursor: pointer;
  margin-bottom: $sd-spacing-sm;
  padding: 0 $sd-spacing-md;

  &.primary {
    background: none;
    background-color: $sd-green-primary;
    @include boxShadow();

    &:hover {
      background-color: $sd-green-light;
      @include boxShadow-secondary();
    }

    &:active {
      background-color: $sd-green-dark;
    }
    &[disabled] {
      color: $sd-textcolor-green;
      border: solid 1px $sd-green-lighter;
      background-color: white;
      @include boxShadow-xs();
      cursor: default;
      span {
        opacity: 0.4;
      }
    }
  }

  &.secondary {
    opacity: 0.85;
    border: solid 1px $sd-green-primary;
    background-color: $sd-background-light;
    color: $sd-green-dark;

    &:hover {
      background-color: $sd-green-lightest;
    }

    &:active {
      background-color: $sd-green-lighter;
    }

    &[disabled] {
      background-color: white;
      border: solid 1px $sd-green-lighter;
      cursor: default;
      span {
        opacity: 0.4;
      }
    }
  }
}
.buttonLink {
  color: $sd-green-primary;
  border: none;
  background: none;
  outline: none;
  height: $element-height-md;
  width: fit-content;
  display: flex;
  align-items: center;

  &.delete {
    color: $sd-red;
  }
}
