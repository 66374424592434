.addOnItem {
  width: 100%;
  padding: 7px 16px;
  border-radius: 12px;
  border: solid 1.5px #9bb0c4;
  font-family: "HelveticaNeueLTProRoman";
  font-weight: normal;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  &.selected {
    border: none;
    background-color: #e2fef7;
    .checkBoxItem .containerCheckBox .checkmark:before {
      border-color: $sd-green-primary;
    }
    .itemPrice {
      color: $sd-green-primary;
    }
    .itemTitle {
      color: #141417;
    }
  }
  .itemPrice {
    font-size: 14px;
    color: #85858b;
  }
}

.checkBoxItem {
  .itemTitle {
    font-size: 16px;
    padding-left: $sd-spacing-md;
    color: #85858b;
  }
  .itemDescription {
    font-size: 14px;
    padding-left: $sd-spacing-xs;
    color: #85858b;
  }
  .containerCheckBox {
    margin-bottom: 0;
    .checkmark {
      &:before {
        filter: none;
        border: solid 1.5px #9bb0c4;
        border-radius: 3px;
        width: 14px;
        height: 14px;
        padding: 2px;
      }
      img {
        width: 10px;
        height: 10px;
      }
      position: absolute;
      top: 6px;
      width: 14px;
      height: 14px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: none;
    }
  }
}
