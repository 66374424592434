.corporateForm {
  padding: $sd-spacing-xl;
  .input::before {
    display: none;
  }

  .caption {
    color: #979797;
  }

  form {
    > .header {
      display: flex;
      .title {
        flex-grow: 1;
        font-size: 20px;
      }
      .cancel {
        margin-right: $sd-spacing-sm;
      }
    }
    > .content {
      grid-area: details;
      .details {
        .avatar {
          grid-area: avatar;
          width: 115px;
          position: relative;
          input {
            position: absolute;
            outline: none;
            top: 0;
            width: 100%;
            height: 100%;
            border-bottom: none;
            cursor: pointer;
            opacity: 0;
            &:disabled {
              cursor: default;
            }
            &::-webkit-file-upload-button {
              visibility: hidden;
            }
          }
          img {
            position: absolute;
            border-radius: 10px;
            top: 0;
            object-position: center;
            height: 115px;
            width: 115px;
            object-fit: cover;
          }
          svg {
            top: 0;
            position: absolute;
            height: 115px;
            width: 115px;
            cursor: pointer;
          }
        }
        .name {
          grid-area: name;
        }
        .groupName {
          grid-area: groupName;
        }
        .tags {
          grid-area: tags;
        }
        .caseType {
          grid-area: caseType;
        }
        .coverage {
          grid-area: coverage;
          .select-wrapper {
            margin-bottom: 0;
          }
          .caption {
            font-size: 10px;
            padding: $sd-spacing-xxs;
          }
        }
        display: inline-grid;
        grid-gap: $sd-spacing-xs;
        grid-template-areas:
          "avatar name name groupName"
          "avatar tags coverage caseType";
        grid-template-columns: 120px 200px 200px 400px;
      }

      .billingContacts {
        grid-area: billingContacts;
        grid-template-columns: auto auto;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        .billingContainer {
          display: grid;
          grid-gap: $sd-spacing-xs;
          .input.sm {
            margin-bottom: 0;
          }
          .icon {
            grid-area: icon;
            padding-top: $sd-spacing-xs;
          }
          .billingAddress {
            grid-area: billingAddress;
          }
          .unit {
            grid-area: unit;
          }
          .postcode {
            grid-area: postcode;
          }
          .postcode {
            grid-area: postcode;
          }
          .country {
            grid-area: country;
          }
          .state {
            grid-area: state;
          }
          .defaultPayer {
            display: flex;
            align-items: center;
            font-size: 16px;
            grid-area: defaultPayer;
            .containerCheckBox {
              margin-bottom: 22px;
              padding-left: 35px;
            }
            .text {
              .terms {
                font-size: 12px;
                color: $text-color-captions;
              }
            }
          }
          .paymentMethods {
            grid-area: paymentMethods;
            .paymentMethodsContainer {
              border-top: 1px solid #eeeeee;
              border-bottom: 1px solid #eeeeee;
              margin-top: 10px;
              padding: $sd-spacing-sm 0;
              .title {
                display: flex;
                align-items: center;
                color: #4f657a;
                margin-bottom: $sd-spacing-sm;
                svg {
                  margin-right: $sd-spacing-md;
                }
              }
              .subtitle {
                font-size: 14px;
                span {
                  color: $text-color-captions;
                  font-size: 12px;
                }
              }
              .pmContainer {
                display: flex;
                .addCard {
                  align-self: flex-end;
                  margin-bottom: $sd-spacing-xs;
                  border: none;
                  padding: none;
                  margin: none;
                }
                .pmItemContainer {
                  font-size: 15px;
                  padding-top: $sd-spacing-sm;
                  .pmItem {
                    padding: $sd-spacing-sm 0;
                    span {
                      padding-left: $sd-spacing-lg;
                      text-transform: capitalize;
                    }
                    .cardDetail {
                      text-transform: capitalize;
                    }
                    .input {
                      width: 300px;
                      padding: $sd-spacing-xs 0 0 $sd-spacing-lg;
                    }
                  }
                }
              }
            }
          }
          grid-template-columns: 40px auto auto;
          grid-template-areas:
            "icon billingAddress billingAddress"
            "icon unit postcode"
            "icon country state"
            "paymentMethods paymentMethods paymentMethods"
            "defaultPayer defaultPayer defaultPayer";
        }
        .businessContacts {
          .contactsContainer {
            display: grid;
            grid-template-columns: auto auto auto;
            .input.sm {
              margin-right: $sd-spacing-xs;
            }
            .delete {
              text-align: right;
              .secondary {
                border: none;
              }
            }
          }
        }
        .addContact {
          &.secondary {
            border: none;
          }
        }
      }
      .prices {
        grid-area: prices;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        .priceActions {
          display: flex;
          font-size: 16px;
          width: 500px;
          justify-content: space-between;
          margin-top: 12px;
          .resetPrices,
          .addWorkingHours {
            color: $sd-green-primary;
            cursor: pointer;
          }
        }

        .priceItem {
          &.open {
            .buttonRed {
              border-right: 1px solid #d1d9e6;
            }
            .price {
              .priceSymbol {
                color: #141417;
              }
              .priceInput {
                color: #141417;
              }
            }
            .react-datepicker__input-container {
              input {
                color: #141417;
              }
            }
          }
          display: flex;
          width: 500px;
          height: 56px;
          margin: $sd-spacing-sm 0;
          padding: 8px 24px 8px 16px;
          border-radius: 10px;
          box-shadow: 4px 4px 8px 0 #d1d9e666;
          border: solid 1px #d1e4ec;
          font-size: 16px;
          line-height: 2.2;
          .arrow {
            padding: 12px 8px 0 8px;
            img {
              width: 27px;
            }
          }
          .price {
            flex-grow: 1;
            position: relative;
            .priceSymbol {
              position: absolute;
              left: 45px;
              color: #85858b;
              font-size: 14px;
              top: 2px;
            }
            .priceInput {
              width: 70px;
              height: 36px;
              margin: 0 0 0 38px;
              padding: 8px 8px 8px 30px;
              border-radius: 10px;
              box-shadow: 2px 2px 2px 0 rgba(209, 217, 230, 0.4);
              border: solid 1px #d1e4ec;
              outline: none;
              color: rgba(32, 0, 0, 0.6);
              font-size: 14px;
              &:disabled {
                background-color: white;
              }
            }
          }

          .buttonRed {
            color: #ff6b6b;
            text-align: left;
            padding-right: 20px;
            padding-top: 2px;
            width: 72px;
            cursor: pointer;
          }
          .buttonGreen {
            padding-left: 20px;
            color: #35b0ad;
            text-align: right;
            padding-top: 2px;
            width: 50px;
            cursor: pointer;
          }
        }
      }
      .paymentMethods {
        grid-area: paymentMethods;
      }
      display: grid;
      grid-gap: $sd-spacing-xl;
      grid-template-areas:
        "details details"
        "billingContacts prices";
      grid-template-columns: 700px auto;
    }
  }
}
.tabContainer {
  .tabHeader {
    display: flex;
    border-bottom: 1px solid #eeeeee;
    .tabItem {
      padding: $sd-spacing-xxs $sd-spacing-sm;
      font-size: 14px;
      text-align: center;
      cursor: pointer;
      &.active {
        border-bottom: 4px solid #00c3b5;
      }
    }
  }
  .tabContent {
    padding: $sd-spacing-sm 0;
  }
}
.createCard {
  margin-top: 20vh;
  padding: $sd-spacing-md;
  width: 500px;
  min-width: 500px;
  left: calc((100vw - 500px) / 2);
  .title {
    display: flex;
    padding-bottom: $sd-spacing-md;
    span:nth-child(1) {
      flex-grow: 1;
    }
    span:nth-child(2) {
      cursor: pointer;
      align-self: flex-end;
    }
  }
}
