.dropdown {
  .select-wrapper {
    margin-bottom: 0;
    &.sm {
      height: 22px;
      line-height: 22px;
    }
    .select-list {
      border-radius: 12px;
      border: solid 1px $sd-green-primary;
      .select-scroll-list .select-list-item {
        padding: 0 $sd-spacing-sm;
      }
    }
    button {
      border-radius: 12px;

      &.select-header {
        border: none;
        box-shadow: none;
        -webkit-box-shadow: none;
        height: 22px;
        line-height: 22px;
        img {
          position: absolute;
          left: 0;
        }
        .select-header-title {
          padding-left: $sd-spacing-md;
        }
      }
    }
  }
}
