.linkGray {
  font-weight: 600;
  color: #979797;
}

.linkGreen {
  font-size: $font-size-jarvis-md;
  font-weight: 400;
  line-height: 1.29;
  color: #00c3b5;
}

.linkBlue {
  font-size: $font-size-jarvis-sm;
  font-weight: 400;
  line-height: 1.29;
  color: #126ef8;
}

.linkRed {
  font-size: $font-size-jarvis-md;
  font-weight: 600;
  color: #e4104f;
}
