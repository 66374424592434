.autoCompleteUM {
  position: relative;

  .info {
    position: absolute;
    right: 10px;
    top: 7px;
  }

  &.highlighted input:focus {
    border-color: #2ed9e5;
  }

  .inputUM {
    margin-bottom: 0;
    &.open {
      input {
        border: solid 1.5px #e7edf4;
        font-size: 12px;
        border-radius: 6px;
      }
    }

    .inputContainer span svg {
      height: 16px;
      margin-right: 0px;
      path:first-child {
        fill: #1065e4;
      }
    }
  }

  .list {
    padding: 8px 0;
    width: 100%;
    position: absolute;
    top: 35px;
    z-index: 2;
    background-color: #ffffff;
    border: solid 1px #d5f2f0;
    border: none;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.25);
    font-family: "Open Sans";
    font-size: 12px;
    cursor: pointer;
    max-height: 300px;
    overflow-y: auto;
    border-radius: 4px;
    @include scrollbar();

    .listItem {
      padding: $sd-spacing-xs $sd-spacing-sm;
      height: 44px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #000000;
      letter-spacing: -0.12px;
      &:hover {
        background-color: rgba(218, 218, 218, 0.2);
      }

      .avatar {
        width: 14px;
        height: 14px;
        display: flex;
        align-self: center;
        margin-right: 10px;
        img {
          vertical-align: middle;
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        margin-right: auto;

        .subtitle {
          color: #6c6c6c;
          font-size: 10px;
          letter-spacing: -0.1px;
        }
      }

      .itemInfo {
        display: flex;
        align-items: center;
        color: #1065e4;
        font-family: "Open Sans";
        font-size: 10px;
        font-weight: bold;
        text-transform: uppercase;
        margin-left: 8px;
        svg {
          height: 16px;
          margin-right: 0px;
          path:first-child {
            fill: #1065e4;
          }
        }
      }

      &.subscreenLink {
        height: 38px;
        color: #15bfcc;
        font-weight: 600;
        letter-spacing: -0.12px;
      }
    }

    .loader {
      > div {
        margin: auto;
      }
    }
  }
}
