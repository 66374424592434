.containerCheckBoxUM {
  display: block;
  position: relative;
  padding-left: 30px;
  line-height: $sd-spacing-sm + $sd-spacing-xxs;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .text {
    font-size: $sd-spacing-sm;
    font-family: "Open Sans";
    font-size: 12px;
    color: #979797;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    box-shadow: none;

    &:before {
      content: "";
      width: 18px;
      height: 18px;
      border-radius: 4px;
      border: solid 1px #e7edf4;
      position: absolute;
    }

    img {
      padding-top: 2px;
      width: $sd-spacing-sm;
    }
  }

  &.checked .checkmark {
    background-color: $sd-blue-light;
    &:before {
      border: solid 1px $sd-blue-light;
      background-color: initial;
    }
  }

  &.autocompleteMargin {
    margin-left: 54px !important;
    margin-top: 8px !important;
  }
}
