.sdTimePicker {
  position: relative;

  .react-datepicker {
    box-shadow: none;
  }

  .displayArrow {
    position: absolute;
    top: 10px;
    left: 0px;
    z-index: 1;
    img {
      width: 18px;
    }
  }
  &.disableFirstChild {
    .react-datepicker__time-list-item:first-child {
      display: none;
    }
  }

  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker-popper .react-datepicker .react-datepicker__header {
    display: none;
  }

  .react-datepicker__input-container {
    width: 78px;
    border-radius: 10px;
    border: none;
    input {
      border: none;
      outline: none;
      padding: 0 $sd-spacing-xs;
      color: #85858b;
      width: 78px;
      background-color: transparent;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 14px;
      text-align: center;
      color: rgba(32, 0, 0, 0.6);
    }
  }

  .react-datepicker__time-container,
  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    border-radius: 10px;
    width: 76px;
  }
  .react-datepicker-popper {
    min-width: 76px;
    z-index: 2;
  }
  .react-datepicker-popper .react-datepicker {
    border-radius: 10px;
    border: 1px solid $sd-green-primary;
  }
  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    border-radius: 10px;
    li.react-datepicker__time-list-item {
      height: 36px;
      line-height: 36px;
      padding: 0;
      text-align: left;
      padding-left: 10px;
      &:hover {
        background-color: #c2f9ed;
        color: #141417;
      }
    }
  }
}
