.paymentMethodUM {
  > .content {
    .individualBilling,
    .corporateBilling {
      display: flex;
      align-items: center;
      margin: $sd-spacing-sm;
      gap: $sd-spacing-sm;
      .text {
        flex-grow: 1;
      }
    }
    .corporateBilling {
      .confirm {
        cursor: not-allowed;
      }
    }
    hr {
      border: none;
      border-top: 1px solid #eee;
    }
    .createStripeCard {
      margin: 0 $sd-spacing-sm;
      .stripeCardElement {
        border-radius: 6px;
        border-color: #eee;
        font-size: 12px;
      }

      .submitButton {
        text-align: right;
        button {
          width: fit-content;
          border: none;
          box-shadow: none;
          color: #00c3b5;
          background-color: #c8ebe8;
          padding: 6px 12px;
          font-size: 14px;
          border-radius: 6px;
          height: unset;
        }
      }
    }
    .promoCodeContainer {
      display: flex;
      padding: $sd-spacing-sm;
      align-items: center;
      gap: $sd-spacing-sm;
      .codeCheck {
        height: 16px;
        width: 16px;
      }
    }
    .paymentMethodList {
      .radioButtonUM {
        padding: 4px 19px;
        height: 40px;
        &:hover {
          background-color: #e0f6f5;
          cursor: pointer;
          &.disabled {
            background-color: #e7eaee;
            cursor: not-allowed;
          }
        }
        .content {
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-transform: capitalize;
          gap: $sd-spacing-xs;
          &:hover {
            .delete {
              display: block;
              width: 20px;
              cursor: pointer;
            }
          }
          .delete {
            display: none;
          }
          .paymentName {
            align-items: center;
            display: flex;
            svg {
              width: 24px;
              margin-right: 8px;
            }
          }
          .caption {
            color: $text-color-captions;
            flex-grow: 1;
            text-align: right;
            display: flex;
            justify-content: flex-end;
            .inputUM {
              width: 240px;
            }
            &.free {
              text-transform: none;
            }
            &.active {
              color: #ff8450;
            }
          }
        }
      }
      &.free {
        margin-top: $sd-spacing-sm;
      }
      .corporatePaymentMethod {
        .tagUM {
          font-size: 10px;
          height: 19px;
          background-color: #1065e4;
          color: white;
          cursor: default;
          margin-right: 11px;
          cursor: default;
          text-transform: uppercase;
        }
      }
      .corporatePaymentMethodCaption {
        color: #ff5c8e;
      }
    }
  }
}
