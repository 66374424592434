.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(249, 253, 255, 0.84);
  z-index: 4001;
  &.previewModal {
    background: rgba(0, 0, 0, 0.6);
  }
  &.noBackgroundModal {
    background: rgba(0, 0, 0, 0);
  }
}

.modal-main {
  position: fixed;
  background-color: $sd-background-light;
  width: 800px;
  box-shadow: 3px -2px 12px 0 rgba(221, 224, 230, 0.54), 10px 10px 20px 0 rgba(228, 235, 246, 0.4),
    -10px -10px 20px 0 rgba(232, 233, 236, 0.55);
  border-radius: $sd-border-radius-lg;
  min-width: 800px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .content {
    overflow: visible;
    @include scrollbar();
  }
}

@import "../pages/eRoster/duplicateModal.scss";

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  margin-top: 20vh;
  width: 480px;
  min-width: 480px;
  top: calc((100vh - 650px) / 2);

  .content {
    display: flex;
    flex-direction: column;
    padding: 32px 24px 24px 24px;

    .text {
      padding-bottom: 20px;
    }

    .title {
      font-family: "PlutoSans";
      font-weight: 400;
      font-size: $font-size-lg;
    }

    .description {
      font-family: "HelveticaNeueLTProRoman";
      font-size: $font-size-sm;
      span {
        font-weight: 600;
      }
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 9px;
      height: $sd-spacing-xl;
      width: 48%;
      margin: $sd-spacing-xxs * 3 0 0 0;
      font-size: $font-size-sm;
      cursor: pointer;
      &.cancel {
        background: #ffffff;
        color: $sd-intense-green;
        border: 1px solid $sd-intense-green;
      }
      &.validate {
        background: $sd-intense-green;
        color: #ffffff;
        border: 1px solid $sd-intense-green;
      }
    }
  }

  .cross {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 20px;
  }
}
