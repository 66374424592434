.addressFormUM {
  .addressAutocompleteUM {
    input {
      font-family: inherit;
      height: 30px;
      padding: 0 10px;
    }
    .suggestionContainer {
      width: calc(100% - 56px);
    }
    .googleMapAutocompleteInput {
      font-size: $font-size-jarvis-sm;
    }
  }

  .unitPostCode {
    input {
      flex-grow: 1;
    }
    .list {
      left: -205px;
      min-width: 460px;
    }
  }

  .btnSave {
    background: #d9f5f3;
    color: #00c3b5;
    border-radius: 6px;
    font-weight: 600;

    &:hover {
      background: #d9f5f3;
      color: #00c3b5;
    }
  }
}
