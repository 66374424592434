.inputPhone {
  display: flex;
  .input {
    margin-bottom: 0;
    &::before {
      display: none;
    }
    input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: none;
    }
  }

  .select-wrapper {
    width: 100px;
    button {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .select-header {
      position: relative;
      box-shadow: none;
      -webkit-box-shadow: none;

      .select-header-title {
        position: absolute;
      }
      img {
        position: absolute;
        right: -5px;
      }
    }
    .select-list-item {
      padding: $sd-spacing-xxs $sd-spacing-xs;
    }
  }
}
