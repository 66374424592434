#login {
  .error {
    color: red;
  }
  .changePassword {
    width: 100%;
    text-align: center;
    color: $sd-green-primary;
    font-size: 12px;
  }
  min-height: 100vh;
  #left {
    position: absolute;
    z-index: 1;
    left: 0;

    padding: $sd-spacing-lg;
    @include moreThan(md) {
      left: 20%;
      padding: $sd-spacing-lg * 3;
    }
    @include moreThan(lg) {
      padding: 0;
    }
    margin-top: 10%;
    .content {
      margin-left: auto;
      margin-right: auto;
      @include moreThan(lg) {
        width: $sd-spacing-lg * 15;
      }

      .input {
        margin-top: $sd-spacing-sm;
        margin-bottom: 0;
      }
      .containerCheckBox .checkmark {
        top: 0;
      }
      label {
        font-size: $font-size-sm;
        margin-bottom: $sd-spacing-md;
      }
      img {
        display: block;
        width: 80%;
      }
      h1 {
        margin-top: $sd-spacing-lg * 1.5;
        @include moreThan(lg) {
          margin-top: $sd-spacing-lg * 3;
        }
        font-size: $font-size-lg;
        font-weight: lighter;
      }
    }
  }
  #right {
    display: none;
    @include moreThan(lg) {
      display: block;
      background-image: url("https://public.speedoc.com/design/images/speedoc.com/background/launching-bg.svg");
      position: absolute;
      background-repeat: no-repeat;
      background-position: 100% 0;
      background-size: 40%;
      width: 100%;
      height: 100%;
      top: 15vh;
      left: 0;
      z-index: 0;
    }

    @include moreThan(xl) {
      background-size: auto;
      top: 0;
    }
  }
}
