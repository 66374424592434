.confirmCancellationUM {
  .headerUM {
    svg {
      cursor: pointer;
    }
  }

  .buttonUM {
    font-weight: initial;
  }
  .serviceSection {
    display: flex;
    margin: 0 16px;
    margin-bottom: 8px;
    flex-grow: 1;
    font-family: "PlutoSans", "Helvetica";
    font-size: 18px;
    color: #3ebebc;

    .subtitle {
      margin-left: 8px;
      font-size: 12px;
      color: #6c6c6c;
      margin-top: 4px;
    }
  }
  .scheduleSection {
    display: flex;
    flex-direction: column;
    margin: 0 16px;
    .scheduleUM {
      font-weight: 600;
    }
    .editAddressText {
      display: block;
    }
  }

  .userSection {
    display: flex;
    margin: 20px 16px;
    justify-content: space-between;
    .user {
      display: flex;
    }
    .userDetails {
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      align-self: center;
      span {
        font-weight: 600;
        &.light {
          font-weight: 300;
        }
      }
      a {
        color: #126ef8;
        text-decoration: underline;
      }
    }
    .userEdit {
      display: flex;
      width: 100%;
    }
  }

  .actionSection,
  .contactedSection {
    margin: 20px 16px;
  }

  .reasonSection {
    margin: 0 16px;
    color: $text-color-captions;
    .redText {
      color: #ff5c8e;
    }
  }

  .contactedSection {
    .containerCheckBoxUM {
      padding-left: 44px;
      .text {
        color: black;
      }
    }
    .checkmark {
      margin-left: 2px;
    }
  }

  hr {
    border: none;
    border-top: 1px solid #eee;
    margin: 20px 0px;
  }

  .footerUM {
    .footerLine {
      margin-top: 0px;
    }
    .footerContainer {
      padding: $sd-spacing-xs $sd-spacing-sm $sd-spacing-sm $sd-spacing-sm;

      .actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: $sd-spacing-sm;
        gap: $sd-spacing-sm;
        .rightActions {
          display: flex;
          align-items: center;
          .primary {
            margin-left: 12px;
          }
        }
        &.alignRight {
          justify-content: flex-end;
        }
      }
    }
  }
}
