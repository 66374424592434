.marker {
  position: relative;
  z-index: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;

  &:hover {
    z-index: 2;
  }

  &.transparent {
    display: none;
  }

  .pulse {
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    opacity: 0.6;
    transform: scale(1);
    z-index: -1;

    &.pulsating {
      animation-name: pulse;
      animation-duration: 0.5s;
      animation-iteration-count: infinite;
      animation-delay: 100ms;
    }
  }

  .info {
    position: absolute;
    bottom: 50px;
    width: 225px;

    &:before {
      content: "";
      position: absolute;
      border-left: 10px solid #fff;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      bottom: 1px;
      left: 50%;
      transform: translate(-50%) rotate(90deg);
    }
  }

  .markerWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 5px solid;
    border-radius: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: bolder;
  }

  &.provider {
    .pulse {
      background-color: #fff;
    }

    .tooltip {
      font-size: 10px;
    }

    .markerWrapper {
      width: 50px;
      height: 50px;
      background-color: #82bbfe;
      border-color: #fff;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
      color: #fff;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        border-left: 10px solid #fff;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        bottom: -16px;
        left: 50%;
        transform: translate(-50%) rotate(90deg);
      }

      .avatarWrapper {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        border-radius: 100%;
        border-color: #fff;
        overflow: hidden;
        pointer-events: none;

        .avatarImg {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &.Delivery {
      .pulse {
        background-color: #ee7b75;
      }

      .markerWrapper {
        border-color: #ee7b75;
        border-radius: 30%;

        &:before {
          border-left-color: #ee7b75;
        }
      }
    }

    &.Video {
      .pulse {
        background-color: #48a5f1;
      }

      .markerWrapper,
      .avatarWrapper {
        border-color: #48a5f1;

        &:before {
          border-left-color: #48a5f1;
        }
      }
    }

    &.Visit {
      .pulse {
        background-color: #5baeac;
      }

      .markerWrapper,
      .avatarWrapper {
        border-color: #5baeac;

        &:before {
          border-left-color: #5baeac;
        }
      }
    }
  }

  &.service {
    .info {
      bottom: 35px;
    }

    .markerWrapper {
      width: 35px;
      height: 35px;
      background-color: #fff;
      border-color: #52cd92;
      color: #52cd92;

      &:before {
        content: "";
        position: absolute;
        width: 35px;
        height: 35px;
        border-radius: 100%;
        background-color: #52cd92;
        transform: scale(1.3);
        opacity: 0.5;
        z-index: -1;
      }

      .name {
        position: relative;
        z-index: 2;
      }
    }

    &.accepted {
      .markerWrapper {
        border-color: #ff7370;
        color: #ff7370;

        &:before {
          background-color: #ff7370;
        }
      }
    }

    &.started {
      .markerWrapper {
        border-color: #fa8f41;
        color: #fa8f41;

        &:before {
          background-color: #fa8f41;
        }
      }
    }
  }

  @keyframes pulse {
    from {
      opacity: 0.6;
      transform: scale(1);
    }
    to {
      opacity: 0;
      transform: scale(1.7);
    }
  }
}
