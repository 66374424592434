.dateTimePickerUM {
  display: inline-flex;
  gap: $sd-spacing-xs;
  .react-datepicker {
    font-size: 12px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);
    border: none;
  }
  .react-datepicker__day {
    &:hover {
      background-color: #eee;
      border-radius: 50%;
    }
    &--selected {
      background-color: #70757a;
      border-radius: 50%;
    }
  }
  button.react-datepicker__navigation {
    border-radius: 0;
    border: 6px solid transparent;
    &.react-datepicker__navigation--previous {
      left: 140px;
    }
  }

  .react-datepicker__current-month {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.25px;
    line-height: 20px;
    color: #3c4043;
    margin-bottom: $sd-spacing-xs;
    padding-left: $sd-spacing-sm;
    text-align: left;
  }

  .react-datepicker__header {
    background-color: white;
    border-bottom: none;
  }
  .react-datepicker__day-name {
    color: #70757a;
  }
  .react-datepicker__month {
    margin-top: 0;
  }
  .react-datepicker__day-name,
  .react-datepicker__day {
    width: $sd-spacing-md;
    height: $sd-spacing-md;
    line-height: $sd-spacing-md;
    font-size: $font-size-jarvis-xs;
  }
  .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker-popper {
    &[data-placement^="bottom"] {
      margin-top: $sd-spacing-xxs;
    }
  }
  .react-datepicker__input-container {
    input {
      color: white;
      border: none;
      background-color: #5bb4ff;
      border-radius: 6px;
      padding: $sd-spacing-xs;
      font-family: inherit;
      position: relative;
      outline: none;
      font-size: $font-size-jarvis-sm;
      cursor: pointer;
      &::placeholder {
        color: white;
      }
    }
  }
  .timePicker {
    .react-datepicker__time-container,
    .react-datepicker__input-container input,
    .react-datepicker__time .react-datepicker__time-box {
      width: 80px;
    }
    .react-datepicker__time-list {
      @include scrollbar();
    }
    .react-datepicker__header {
      padding-top: $sd-spacing-xxs;
    }
    .react-datepicker__header--time {
      padding-bottom: 0;
      .react-datepicker-time__header {
        display: none;
      }
    }
    position: relative;
    svg {
      position: absolute;
      top: 14px;
      right: 10px;
      cursor: pointer;
    }
  }
  .datePicker {
    .react-datepicker__input-container {
      input {
        width: 135px;
      }
    }
    position: relative;
    svg {
      position: absolute;
      top: 14px;
      right: 10px;
      cursor: pointer;
    }
  }
  &.disabled {
    svg {
      path {
        fill: #4f657a;
      }
    }
    .react-datepicker__input-container {
      input {
        color: #4f657a;
        background-color: #e7ecf4;
        &::placeholder {
          color: #9fb5ca;
        }
      }
    }
  }
}
