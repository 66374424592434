.createUserUM {
  .content {
    .formContainer {
      padding: $sd-spacing-sm;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr auto;
      gap: $sd-spacing-sm;

      .inputUM {
        display: inline-flex;
      }
      .firstName {
        grid-column: span 4;
      }
      .lastName {
        grid-column: 3 / span 2;
      }
      .birthdatePicker {
        grid-column: 1 / span 2;
        display: flex;
        align-items: center;
        gap: 12px;
        .birthdate {
          .react-datepicker__year-select,
          .react-datepicker__month-select {
            border: none;
            outline: none;
          }
          .react-datepicker__input-container input {
            width: 172px;
            border: 1px solid #e7edf4;
            border-radius: 6px;
            height: 30px;
            padding: 0 10px;
            font-family: "Open Sans";
            font-size: 12px;
            background: #ffffff;
            color: black;
            &::placeholder {
              font-family: "Open Sans";
              font-size: 12px;
              color: #979797;
            }
            &:focus {
              outline: none;
            }
            &:disabled {
              cursor: not-allowed;
            }
          }
        }
        .inputUM {
          flex-grow: 1;
          input {
            width: 100%;
            &::-webkit-inner-spin-button,
            &::-webkit-calendar-picker-indicator {
              display: none;
              -webkit-appearance: none;
            }
            &::-webkit-datetime-edit-year-field,
            &::-webkit-datetime-edit-month-field,
            &::-webkit-datetime-edit-day-field {
              color: #141417;
              padding: 3px;
            }
          }
          &.empty {
            input {
              &::-webkit-datetime-edit,
              &::-webkit-datetime-edit-month-field,
              &::-webkit-datetime-edit-day-field,
              &::-webkit-datetime-edit-year-field {
                color: #979797;
              }
            }
          }
        }
      }
      .gender {
        grid-column: 3 / span 2;
      }
      .inputPhoneUM {
        grid-column: span 4;
        align-items: center;
      }
      .email {
        grid-column: span 4;
        align-items: center;
      }
      .verification {
        grid-column: span 4;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .verificationContainer {
          display: flex;
          gap: 16px;

          .grow {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            gap: 16px;

            .verificationId {
              display: flex;
              align-items: center;

              .idType {
                display: flex;
                align-items: center;

                .inputGroup {
                  width: 80px;
                }
                svg {
                  width: 24px;
                  height: 24px;
                }
                input {
                  border-top-right-radius: 0;
                  border-bottom-right-radius: 0;
                  border-right: 0;
                }
              }
              .idNumber {
                flex-grow: 1;

                input {
                  border-top-left-radius: 0;
                  border-bottom-left-radius: 0;
                }
              }
            }
          }
        }
        .idFullName {
          margin-left: 36px;
        }
        .idPhoto {
          width: 88px;

          img {
            cursor: pointer;
            margin: auto;
            max-height: 76px;
          }
          .noPhoto {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 8px;
            padding: 8px;
            height: 100%;
            font-size: 10px;
            line-height: 10px;
            text-align: center;
            color: #becbd6;
            border: 1.5px solid #e7edf4;
            border-radius: 4px;
          }
        }
      }

      .verifiedUserInfo {
        margin-left: 4px;
        grid-column: span 4;
        display: flex;
        justify-content: space-between;
        .info {
          display: flex;
          align-items: center;
          justify-content: center;
          .icon {
            margin-right: 14px;
            svg {
              vertical-align: middle;
            }
          }
          .text {
            display: table;
            .title {
              font-weight: 600;
              display: table-row;
            }
            .subtitle {
              display: table-row;
              color: #6c6c6c;
            }
          }
        }
        .checkBoxInput {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 6px;
          .containerCheckBoxUM {
            .text {
              color: #000000;
            }
          }
        }
      }
      .hr1,
      .hr2,
      .hr3,
      .hr4 {
        grid-column: span 4;
        border: none;
        border-top: 1px solid #eee;
        margin: 0 -16px;
      }
      .sendSMSLink {
        grid-column: 1 / span 2;
      }
      .sendResetPasswordLink {
        grid-column: span 4;
      }
      .sendResetPasswordLink,
      .sendSMSLink {
        margin-right: auto;
        margin-left: 35px;
        text-decoration: underline;
      }
      .copyLink {
        grid-column: 3 / span 2;
        display: flex;
        margin-left: auto;
        &:hover {
          text-decoration: underline;
        }
        svg {
          margin-right: 6px;
          align-items: center;
        }
      }
    }
  }
  .footerUM {
    margin: 16px;
    display: flex;
    justify-content: flex-end;
    .loader {
      display: flex;
      flex-direction: row-reverse;
    }
    .buttonUM {
      display: inline-flex;
    }
    .linkGreen {
      padding: 8px 12px;
    }
  }
}
