$sd-text-xxs: 8px;
$sd-text-xs: 11px;
$sd-text-sm: 13px;
$sd-text-md: 16px;
$sd-text-lg: 25px;
$sd-text-xl: 30px;
$sd-text-xxl: $element-height-sm;

p {
  font-size: $sd-text-md;
  line-height: 1.3em;
}

h1,
h2,
h3,
h4 {
  line-height: 1.3em;
}

h1 {
  font-size: $sd-text-xl;
}

h2 {
  font-size: $sd-text-lg;
}

h3 {
  font-size: $sd-text-md;
}

h4 {
  font-size: $sd-text-sm;
}
