.adminSettings {
  font-family: "Helvetica Neue";
  box-shadow: -1px -2px 8px 0 rgba(207, 214, 226, 0.26);
  border-radius: 20px;

  .header {
    display: grid;
    grid-template-columns: 8fr auto auto auto 0.5fr;
    box-shadow: inset 3px -2px 12px rgba(221, 224, 230, 0.55);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    .buttonsHeader {
      align-self: center;
      justify-self: center;
      border-left: solid 1px $grey-primary;
    }

    ul {
      list-style-type: none;
      display: flex;
      padding: 0;
      justify-self: start;
      li {
        padding: $sd-spacing-sm $sd-spacing-md;
        text-transform: uppercase;
        cursor: pointer;

        &:first-child {
          border-top-left-radius: 20px;
        }

        &.active {
          background-color: $sd-background-light;
          height: 100%;
          box-shadow: 0px -10px 12px rgba(221, 224, 230, 0.55);

          button {
            font-weight: 500;
            color: #35b0ad;
            cursor: pointer;
          }
        }

        &.inactive {
          button {
            font-weight: normal;
            color: $text-color-captions;
            cursor: pointer;
          }
        }

        button {
          background-color: transparent;
          padding: 0;
          margin: 0;
          font-family: "PlutoSans";
          font-size: $font-size-xs;
          font-weight: normal;
          text-transform: uppercase;
          height: 100%;
          color: $sd-grey-dark;
          cursor: pointer;
        }
      }
    }
  }

  @import "dispatcher";
}
