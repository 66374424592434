.caseListUM {
  .content {
    display: flex;
    flex-direction: column;
    .inputUM {
      margin: 0 $sd-spacing-sm 0 $sd-spacing-sm;
      svg {
        transform: rotate(90deg);
        path:first-child {
          fill: #979797;
        }
      }
    }

    .selectedCaseContainer {
      min-height: 44px;
      display: flex;
      align-items: center;
      padding: $sd-spacing-xs $sd-spacing-sm $sd-spacing-xs 50px;

      .tagUM {
        svg {
          cursor: pointer;
        }
        margin: $sd-spacing-xxs;
        text-transform: capitalize;
      }
    }
    hr {
      border: none;
      border-top: 1px solid #eee;
    }
    .caseListContainer {
      @include scrollbar();
      flex-grow: 1;
      overflow-y: scroll;
      border-top: 1px solid #eee;
      padding: $sd-spacing-xs $sd-spacing-sm $sd-spacing-xs $sd-spacing-xs;
      margin-bottom: $sd-spacing-sm;
      .existingCaseContainer,
      .createCaseContainer {
        margin: 0 $sd-spacing-xl;
        text-transform: capitalize;
      }
      .newCaseTitle {
        margin: $sd-spacing-sm $sd-spacing-sm $sd-spacing-xs $sd-spacing-xl + $sd-spacing-xxs;
      }
      .title {
        display: flex;
        align-items: center;
        svg {
          margin: 10px;
        }
      }
      .tagUM {
        svg {
          cursor: pointer;
        }
        margin: $sd-spacing-xxs;
      }
    }
  }
}
