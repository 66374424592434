#four0Four {
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 100vh;
  .left {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    align-items: center;
  }
  .right {
    background-image: url("https://public.speedoc.com/design/images/speedoc.com/background/launching-bg.svg");
    background-repeat: no-repeat;
    background-position: 100% 0;
    background-size: 100%;
    width: 100%;
    height: 100%;
  }
}
