.alertUM {
  display: flex;
  color: #726a4d;
  align-items: center;
  padding: $sd-spacing-xs $sd-spacing-md;
  gap: $sd-spacing-sm;
  &.warning {
    background-color: $um-warning;
  }
  &.danger {
    background-color: $um-danger;
  }
  span {
    font-weight: 600;
  }
  .icon {
    svg {
      vertical-align: middle;
      height: 16px;
      width: 16px;
    }
  }
  .text {
    flex-grow: 1;
  }
  .linkGreen {
    font-size: $font-size-jarvis-sm;
  }
}
