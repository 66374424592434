$sd-black: #081d2c !default;
$sd-blue-dark: #001626 !default;
$sd-blue-light: #2986be !default;
$sd-green: #3bcfb6 !default;
$sd-grey: #f6f6f6 !default;
$sd-grey-dark: #b3b3b3 !default;
$sd-grey-light: #3a5060 !default;
$sd-grayish-blue: #4a5e70;
$sd-intense-green: #3ebebc;
$sd-grey-lighter: #f2f7fc;

$sd-background-gray: #fdfeff;
$sd-background-light: #f9fdff;
$highlight: rgba(98, 203, 201, 0.15);
$sd-green-primary: #62cbc9;
$sd-green-dark: #3ca9a6;
$sd-green-light: #62e3c3;
$sd-green-lighter: #d5f2f0;
$sd-green-lightest: #e2fef7;
$sd-green-secondary: #52cd92;
$sd-green-success: #49c7c5;
$sd-blue-primary: #62c9e3;
$sd-blue-light: #13bfcb;
$sd-blue-fb: #2b44fb;
$sd-blue-secondary: #659ec7;
$sd-peach: #f2cdaa;
$sd-fills-black-default: #3e4657;
$sd-orange: #eb986a;
$sd-orange-warning: rgba(227, 98, 18, 0.99);
$sd-red: #ff574d;
$sd-red-error: rgba(227, 46, 18, 0.99);

$sd-textcolor-green: #3ebebc;
$sd-textcolor-black: #141417;
$sd-textcolor-captions: #303033;
$sd-textcolor-black-light: rgba(20, 20, 23, 0.6);
$sd-textcolor-black-disabled: rgba(20, 20, 23, 0.4);

$sd-symptoms-first: #7fd4d3;
$sd-symptoms-second: #9bdddc;
$sd-symptoms-third: #ceeeee;
$sd-symptoms-fourth: #e7f7f7;
