.duplicateRoster {
  width: 340px;
  min-width: 340px;
  box-shadow: 2px 2px 2px rgba(209, 217, 230, 0.398547);
  border-radius: 10px;
  .header {
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    .title {
      font-size: 14px;
    }
    .cross {
      cursor: pointer;
      width: 20px;
      height: 20px;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    padding: 8px 24px;
    .origin,
    .duplicateTo {
      width: 100%;
      .title {
        font-size: 12px;
        margin-bottom: 8px;
      }
      .date {
        font-size: 16px;
      }
      .selectDate {
        display: flex;
        align-items: center;
        column-gap: 8px;
        input {
          width: 140px;
        }
      }
      hr {
        background-color: #ddd;
        height: 1px;
        border: 0;
      }
    }
  }
  .footer {
    text-align: right;
    padding: 16px 24px;

    .confirmButton {
      margin: 0;
      padding: 0;
      height: auto;
      background-color: inherit;
      color: #15bfcc;
      font-size: 12px;
      font-weight: 600;
    }
  }
}
