.step {
  position: relative;
  align-items: center;
  display: flex;

  &.active {
    .stepIcon {
      &:before {
        border: solid 2px $sd-green-primary;
      }
      span {
        color: $sd-textcolor-green;
        opacity: 1;
      }
    }
  }

  &.disabled {
    .stepIcon {
      &:before {
        border: solid 2px #acc4d1;
      }
      span {
        color: #acc4d1;
        opacity: 1;
      }
    }
  }

  .stepIcon {
    position: relative;
    margin-right: $sd-spacing-lg;
    display: flex;
    align-items: center;
    justify-content: center;
    height: $sd-spacing-lg * 2;
    min-width: $sd-spacing-lg * 4;

    &:before {
      position: absolute;
      height: $sd-spacing-lg * 2;
      width: $sd-spacing-lg * 4;
      top: 0;
      left: 0;
      border-radius: 0 $font-size-lg * 2 $font-size-lg * 2 0;
      border: solid 2px $sd-green-lighter;
      background: inherit;
      background-clip: border-box;
      filter: blur(1px);
      content: "";
      box-shadow: 7px 2px 14px 0 #dce3ea, inset 0 9px 5px 0 #f2f7fd, inset -5px -17px 13px 0 white;
      -webkit-box-shadow: 7px 2px 14px 0 #dce3ea, inset 0 9px 5px 0 #f2f7fd, inset -5px -17px 13px 0 white;
    }

    span {
      width: auto;
      font-family: "OpenSansSemiBold", Arial, Helvetica, sans-serif;
      font-size: $font-size-xl;
      line-height: normal;
      letter-spacing: normal;
      color: $sd-textcolor-green;
      opacity: 0.6;
      filter: none !important;
      margin-left: -5px;
    }

    img {
      margin-left: -5px;
      margin-top: -1px;
      z-index: 1;
    }
  }

  .stepTitle {
    font-family: "PlutoSans", Arial, Helvetica, sans-serif;
    font-size: $font-size-md;
    line-height: 1.17;
    letter-spacing: normal;
    color: $sd-textcolor-black;
    display: inline-block;
  }

  .stepSubtitle {
    font-family: "HelveticaNeueLTProRoman", Arial, Helvetica, sans-serif;
    font-size: $font-size-sm;
    color: $sd-textcolor-black;
    line-height: 1.38;
  }

  &.sm {
    .stepIcon {
      margin-right: $sd-spacing-sm;
      height: $sd-spacing-xl;
      min-width: $sd-spacing-xl * 2;

      &:before {
        height: $sd-spacing-xl;
        width: $sd-spacing-xl * 2;
        box-shadow: 7px 2px 14px 0 #dce3ea, inset 0 9px 5px 0 #f2f7fd, inset -5px -5px 13px 0 white;
        -webkit-box-shadow: 7px 2px 14px 0 #dce3ea, inset 0 9px 5px 0 #f2f7fd, inset -5px -5px 13px 0 white;
      }
      span {
        font-size: $font-size-sm;
      }
    }

    .stepTitle {
      font-size: $font-size-sm;
    }
  }
}
