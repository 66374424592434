.adminPlaces {
  .btnNew {
    color: #ffffff;
    font-weight: 600;
    text-transform: capitalize;
    background: #00c3b5;
    border-radius: 6px;

    &:hover {
      color: #ffffff;
      background: #00c3b5;
    }
  }

  .MuiCircularProgress-root {
    z-index: 1;
  }

  .MuiDataGrid-cell {
    cursor: pointer;
  }

  .columnHeader {
    .MuiDataGrid-columnHeaderTitle {
      font-weight: 600;
    }
  }
}
