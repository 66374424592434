.inputPhoneUM {
  display: flex;
  width: 100%;

  .icon {
    margin-right: 12px;
    svg {
      vertical-align: middle;
    }
  }

  .inputUM {
    width: 100%;
  }

  .inputInfo {
    color: #ff5c8e !important;
    font-size:smaller;
    margin-top: 2px;
  }

  > .inputUM .inputContainer {
    input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  .selectUM {
    .inputContainer input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      border-right: none;
    }

    .inputGroup {
      width: 80px;
    }
  }
}
