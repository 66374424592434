textarea {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px $sd-background-light inset;
  }
  border-radius: $sd-border-radius-md;
  @include boxShadow-md-up();
  border: solid 1px $sd-green-lighter;
  background-color: $sd-background-light;
  position: relative;
  z-index: auto;
  margin-bottom: $sd-spacing-sm;
  outline: none;
  padding: $sd-spacing-sm;
  font-family: "HelveticaNeueLTProRoman", Arial, Helvetica, sans-serif;
  &.sm {
    font-size: $font-size-sm;
  }
  font-size: $font-size-lg;
  color: $sd-textcolor-black;

  &::placeholder,
  :-ms-input-placeholder,
  ::-ms-input-placeholder {
    color: $sd-textcolor-black-light;
  }
  &:focus {
    color: $sd-textcolor-green;
  }
  &:disabled {
    color: $sd-textcolor-black-disabled;
  }
}
