.user-cases {
  .accordion {
    width: 100%;

    &:not(:last-child) {
      border-bottom: 0;
    }

    &:before {
      background-color: rgba(0, 0, 0, 0.1);
      height: 1px;
    }

    &:first-of-type {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }

    &:last-of-type {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }

  .accordion-summary {
    flex-direction: row-reverse;

    .add-request-btn {
      visibility: hidden;
    }

    &:hover {
      background-color: #f7f7f8;

      .add-request-btn {
        visibility: visible;
      }
    }

    & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
      transform: rotate(90deg);
    }

    & .MuiAccordionSummary-content {
      margin-left: 1rem;
    }
  }

  .accordion-details {
    padding-left: 46px;
    padding-right: 0;

    .service-row {
      cursor: pointer;
    }
  }
}
