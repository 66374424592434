@import "variables";

@mixin moreThan($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@mixin lessThan($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@mixin boxShadow-xs() {
  box-shadow: 0 1px 14px 0 rgba(62, 67, 72, 0.15);
  -webkit-box-shadow: 0 1px 14px 0 rgba(62, 67, 72, 0.15);
}

@mixin boxShadow-sm() {
  box-shadow: 4px 4px 25px 2px rgba(114, 125, 133, 0.2);
  -webkit-box-shadow: 4px 4px 25px 2px rgba(114, 125, 133, 0.2);
}

@mixin boxShadow() {
  box-shadow: 6px 6px 20px 0 rgba(83, 189, 187, 0.31), -10px -10px 20px 0 white;
  -webkit-box-shadow: 6px 6px 20px 0 rgba(83, 189, 187, 0.31), -10px -10px 20px 0 white;
}

@mixin boxShadow-md() {
  box-shadow: 10px 10px 20px 0 rgba(209, 217, 230, 0.4);
  -webkit-box-shadow: 10px 10px 20px 0 rgba(209, 217, 230, 0.4);
}

@mixin boxShadow-secondary() {
  box-shadow: 6px 6px 20px 0 rgba(71, 231, 185, 0.3), -10px -10px 20px 0 white;
  -webkit-box-shadow: 6px 6px 20px 0 rgba(71, 231, 185, 0.3), -10px -10px 20px 0 white;
}

@mixin boxShadow-md-up() {
  box-shadow: 10px 10px 20px 0 rgba(209, 217, 230, 0.4), -10px -10px 20px 0 white;
  -webkit-box-shadow: 10px 10px 20px 0 rgba(209, 217, 230, 0.4), -10px -10px 20px 0 white;
}
