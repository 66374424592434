.addonListUM {
  .content {
    display: flex;
    flex-direction: column;
    .inputUM {
      margin: 0 $sd-spacing-sm 0 $sd-spacing-sm;
    }
    .selectedAddonContainer {
      padding: $sd-spacing-xs $sd-spacing-sm $sd-spacing-xs 50px;
      .tagInfoUM {
        margin: 2px;
      }
    }
    hr {
      border: none;
      border-top: 1px solid #eee;
    }
    .addonListContainer {
      flex-grow: 1;
      overflow-y: scroll;
      border-top: 1px solid #eee;
      @include scrollbar();
      margin-bottom: $sd-spacing-sm;
      .addonItem {
        padding: $sd-spacing-xs $sd-spacing-sm $sd-spacing-xs 50px;
        cursor: pointer;
        &:hover {
          background-color: #f1f3f4;
        }
        .addonTitle {
          color: black;
        }
        .addonDescription {
          font-size: $font-size-jarvis-xs;
          color: $text-color-captions;
        }
      }
    }
  }
}
