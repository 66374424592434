#adminPrices {
  @import "priceGroup";
  @import "priceRow";
  @import "priceFormRow";

  .serviceTypeTabs {
    .MuiTabs-indicator {
      height: 4px;
      background-color: #00c3b5;
    }

    button {
      padding: 0px;
      color: #4f657a;
      font-size: 14px;
      font-weight: 600;
      text-transform: none;
      margin-right: 48px;

      &.Mui-selected {
        color: black;
        font-weight: 700;
      }
    }
  }

  .specialtySelect {
    font-size: 20px;
    font-weight: 600;
  }

  .headerRow th {
    color: #a3a3a3;
    font-weight: 600;
    padding-top: 0;
  }

  .tableBody td {
    color: #0d1f40;
  }

  .thickBottomBorder {
    border-bottom: 3px solid #eeeeee;
  }

  .thinBottomBorder {
    border-bottom: 1px solid #eeeeee;
  }
}
