.avatarUM {
  display: inline;
  position: relative;
  .sb-avatar {
    .sb-avatar__text {
      span {
        font-size: $font-size-jarvis-sm;
        padding-left: 1px;
      }
    }
  }
  .availabilityStatus {
    width: 10px;
    height: 10px;
    position: absolute;
    top: 16px;
    left: 16px;
    z-index: 1;
    padding: 2px;
    border-radius: 12px;
    border: 2px solid #ffffff;
  }
}
