.tagUM {
  font-family: "Open Sans";
  border-radius: 3px;
  padding: 2px 4px;
  background-color: #dfecf0;
  color: #56738f;
  display: inline-flex;
  align-items: center;
  gap: 3px;
  cursor: pointer;
  svg {
    width: 10px;
    height: 10px;
  }
  &:not(:last-child) {
    margin-right: $sd-spacing-xs;
  }
  &.warning {
    background-color: $um-warning;
  }
  .alert,
  .close {
    width: 12px;
    height: 12px;
    cursor: pointer;
  }
}
