.inputButtonUM {
  display: flex;
  align-items: center;
  gap: 12px;
  &.disabled {
    .text {
      cursor: not-allowed;
    }
  }
  .icon {
    svg {
      vertical-align: middle;
    }
  }
  .text {
    border-radius: $sd-border-radius-xs;
    padding: $sd-spacing-xs 10px;
    flex-grow: 1;
    display: inline-flex;
    justify-content: space-between;
    cursor: pointer;
    &:hover {
      background-color: #f1f3f4;
    }
  }
  .placeholder {
    color: $text-color-captions;
  }
  .freeInput {
    display: flex;
    justify-content: space-between;
    width: 100%;
    text-transform: none;
    .content {
      font-weight: 600;
    }
    .warning {
      color: #ff8450;
    }
  }
}
