.buttonUM {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  height: 34px;
  font-size: 14px;
  font-family: "Open Sans";
  min-width: 80px;
  padding: 8px 12px;
  font-weight: 600;
  margin-bottom: unset;

  &.primary {
    background-color: #00c3b5;
  }

  &.secondary {
    color: #00c3b5;
    background-color: #e0f6f5;
    border-color: #e0f6f5;
  }

  &.confirm {
    color: #00c3b5;
    background-color: #c8ebe8;
  }

  &.noClick {
    cursor: not-allowed;
  }
}
