#landing {
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr 2fr;
  #left {
    color: white;
    .content {
      padding: $sd-spacing-lg;
      button {
        margin-top: $sd-spacing-lg;
      }
    }
  }
  #right {
    color: white;
    .content {
      padding: $sd-spacing-lg;
      display: flex;
      flex-direction: column;
      img {
        max-width: 30vw;
      }
      #chat {
        margin-top: $sd-spacing-lg;
      }
    }
  }
}
