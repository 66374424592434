#dispatcherModalEditor {
  display: grid;
  height: 100%;
  grid-template-rows: auto 1fr;

  .header {
    display: grid;
    grid-template-columns: 8fr auto auto auto 0.5fr;
    box-shadow: inset 3px -2px 12px rgba(221, 224, 230, 0.55);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    .buttonsHeader {
      align-self: center;
      justify-self: center;
      border-left: solid 1px $grey-primary;
    }
    ul {
      list-style-type: none;
      display: flex;
      padding: 0;
      justify-self: start;
      li {
        padding: $sd-spacing-sm $sd-spacing-md;
        text-transform: uppercase;
        cursor: pointer;

        &:first-child {
          border-top-left-radius: 20px;
        }

        &.active {
          background-color: $sd-background-light;
          height: 100%;
          box-shadow: 0px -10px 12px rgba(221, 224, 230, 0.55);

          button {
            font-weight: 500;
            color: #35b0ad;
            cursor: pointer;
          }
        }

        &.inactive {
          button {
            font-weight: normal;
            color: $text-color-captions;
            cursor: pointer;
          }
        }

        button {
          background-color: transparent;
          padding: 0;
          margin: 0;
          font-family: "PlutoSans";
          font-size: $font-size-xs;
          font-weight: normal;
          text-transform: uppercase;
          height: 100%;
          color: $sd-grey-dark;
          cursor: pointer;
        }
      }
    }
    .closeButton {
      color: $text-color-captions;
      background-color: inherit;
      margin: 0;
      height: auto;
      justify-self: end;
    }
    .saveDraft,
    .deleteDraft,
    .cancelRequest,
    .inTransit,
    .inProgress,
    .cancelRequest,
    .finishRequest {
      font-family: "PlutoSans";
      font-size: $font-size-xs;
      text-transform: capitalize;
      white-space: nowrap;
      font-weight: 500;
      margin: 0;
      align-self: center;
      width: fit-content;
      padding: 0 $sd-spacing-sm;
      height: 35px;
    }
  }
  .content {
    #completeEditor,
    #assignEditor,
    #dischargeEditor,
    #summaryEditor {
      height: 100%;
      padding: $sd-spacing-md $sd-spacing-xl;

      .buttonRight {
        text-align: right;
      }

      form {
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .schedule,
        .select-wrapper {
          align-self: center;
          width: 70%;
        }
        .input.schedule {
          width: 100%;
        }
        #forwardRequestButton,
        #dischargeRequestButton,
        #finishRequestButton {
          align-self: flex-end;
          width: auto;
          margin: 0;
        }
      }
    }
  }
  .content #summaryEditor {
    height: 600px;
    overflow-y: scroll;
  }
}

#completeEditor {
  .chargePayment {
    padding-top: 10px;
    font-size: 14px;
    font-weight: normal;
    color: #141417;
    .containerCheckBox {
      padding: 0 15px 0 30px;
    }
  }
  .inputContainer {
    display: flex;
    justify-content: space-between;
    .input.sm {
      width: 185px;
      margin-right: $sd-spacing-sm;
    }
  }
}
