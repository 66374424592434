.card {
  &.extendBottom {
    .content:after {
      position: absolute;
      content: url("https://public.speedoc.com/jarvis/images/card-bottom.svg");
      height: 20px;
      bottom: -19px;
      left: 50%;
      transform: translateX(-50%);
    }
    margin: 0;
  }
  margin: $sd-spacing-sm;
  color: rgba(20, 20, 23, 0.6);
  border-radius: $sd-border-radius-md;
  box-shadow: 1px 1px $sd-spacing-sm 0 rgba(177, 183, 192, 0.4);
  background-color: $sd-background-light;
  transition: all 0.2s ease-in-out;
  p {
    font-size: $font-size-jarvis-xs;
  }

  cursor: pointer;

  .cardHeader {
    padding: 0 $sd-spacing-xs;
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: $sd-spacing-xs;
    border-top-right-radius: $sd-spacing-xs;

    .bookingType {
      padding-left: $sd-spacing-xs;
      color: white;
      text-transform: capitalize;
      font-size: 12px;
      line-height: 1;
      font-weight: 500;
      display: flex;
      .renderTime {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-self: center;
        align-items: center;
        padding: 1px 3px;
        border: 1px solid #888888;
        box-sizing: border-box;
        border-radius: 4px;
        margin-right: 4px;
        width: 40px;
        height: 24px;
        &.danger {
          background: #d30000;
        }
        span {
          font-family: Open Sans;
          font-style: normal;
          font-weight: bold;
        }
      }
      .title {
        align-self: center;
        span {
          font-size: 9px;
          font-weight: 400;
          display: block;
          font-family: Open Sans;
        }
      }
    }
    .price {
      color: white;
      padding-left: 5px;
      border-left: 1px solid white;
      line-height: $sd-spacing-lg;
    }
  }
  .content {
    padding: $sd-spacing-xs;
    border-bottom-left-radius: $sd-border-radius-md;
    border-bottom-right-radius: $sd-border-radius-md;
    position: relative;
    .serviceSection {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 13px;
    }
  }

  &.Visit {
    .cardHeader {
      background-color: #35b0ad;
      .price {
        border-left: solid 1px #287382;
      }
    }
  }
  &.Video {
    .cardHeader {
      background-color: #13a5f8;
      .price {
        border-left: solid 1px #0f7ab8;
      }
    }
  }
  &.Delivery {
    .cardHeader {
      background-color: #ff7370;
      .price {
        border-left: solid 1px #cf5552;
      }
    }
  }
  &.Clinic {
    .cardHeader {
      background-color: #7a78db;
      .price {
        border-left: solid 1px #536ece;
      }
    }
  }

  .caseType,
  .time,
  .name,
  .phoneNumber,
  .provider,
  .id,
  .country,
  .createdDate,
  .payment {
    margin: $sd-spacing-xs;
    font-family: "HelveticaNeueLTProRoman";
    line-height: 1.36;
  }

  .payment {
    display: flex;
    gap: 4px;
    font-size: 10px;
    .tagUM {
      font-size: 10px;
      height: 19px;
      background-color: #1065e4;
      color: white;
      cursor: default;
      float: right;
      text-transform: uppercase;
      white-space: nowrap;
    }
  }

  .caseFollowUp {
    float: right;
    color: #1065e4 !important;
  }

  .caseType,
  .time,
  .payment {
    span {
      color: #36363a;
      text-transform: capitalize;
    }
  }
  .name,
  .phoneNumber {
    span {
      color: #35b0ad;
    }
  }
  .id,
  .country,
  .createdDate {
    span {
      color: #ff8843;
    }
  }
}

.card {
  &.noHoverTransform:hover {
    transform: scale(1);
  }

  &:hover {
    transform: scale(1.1);
  }
}

.accepted {
  .card {
    .content {
      &:before {
        transform: rotate(132deg);
        border-bottom: 4px solid #ff993f;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        height: 4px;
        width: 27px;
        bottom: 7px;
        position: absolute;
        right: -5px;
        content: "";
      }

      &.triaged {
        &:before {
          border-bottom: 4px solid $sd-green-secondary;
        }
      }
    }
  }
}
