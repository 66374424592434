.dropdownTag {
  position: relative;
  .dropdownTagHeader {
    font-size: 16px;
    color: #85858b;
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
      margin-left: $sd-spacing-xs;
      width: 18px;
      height: 18px;
    }
  }
  .dropdownTagPopper {
    z-index: 10;
    position: absolute;
    border-radius: 10px;
    border: 1px solid $sd-green-primary;
    background-color: #f9fdff;
    box-shadow: 2px 2px 2px 0 rgba(209, 217, 230, 0.4);
    display: none;
    top: 30px;
    left: 50%;
    transform: translate(-50%, 0);
    &.open {
      display: block;
    }
    .dropdownTagItem {
      margin: $sd-spacing-xs 10px;
      padding: 2px 8px;
      border-radius: 4px;
      border: 1px solid $sd-green-primary;
      cursor: pointer;
      font-size: 12px;
      text-align: center;
      color: $sd-green-primary;
      display: inline-block;
      &.disabled {
        opacity: 0.6;
        cursor: default;
      }
    }
  }
}
