$xs: 320px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1440px;

$breakpoints: (
  "xs": $xs,
  "sm": $sm,
  "md": $md,
  "lg": $lg,
  "xl": $xl,
  "xxl": $xxl
) !default;

$sd-spacing-xxs: 4px !default;
$sd-spacing-xs: 8px !default;
$sd-spacing-sm: 16px !default;
$sd-spacing-md: 24px !default;
$sd-spacing-lg: 32px !default;
$sd-spacing-xl: 40px !default;
$sd-spacing-xxl: 80px !default;

$sd-border-radius-xs: 5px !default;
$sd-border-radius-sm: 10px !default;
$sd-border-radius-md: 12px !default;
$sd-border-radius-lg: 20px !default;
$sd-border-radius-xl: 30px !default;
$sd-border-radius-xxl: 35px !default;

$font-size-xss: 13px !default;
$font-size-xs: 14px !default;
$font-size-sm: 16px !default;
$font-size-md: 18px !default;
$font-size-lg: 20px !default;
$font-size-xl: 24px !default;
$font-size-xxl: 32px !default;
$font-size-wide: 50px !default;

$element-height-md: 56px !default;
$element-height-sm: 40px !default;
