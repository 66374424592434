@font-face {
  font-family: "PlutoSans";
  src: url("https://public.speedoc.com/fonts/PlutoSans/PlutoSansLight.eot");
  src: url("https://public.speedoc.com/fonts/PlutoSans/PlutoSansLight.eot?#iefix") format("embedded-opentype"),
    url("https://public.speedoc.com/fonts/PlutoSans/PlutoSansLight.woff") format("woff"),
    url("https://public.speedoc.com/fonts/PlutoSans/PlutoSansLight.woff2") format("woff2");
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "PlutoSans";
  src: url("https://public.speedoc.com/fonts/PlutoSans/PlutoSansRegular.eot");
  src: url("https://public.speedoc.com/fonts/PlutoSans/PlutoSansRegular.eot?#iefix") format("embedded-opentype"),
    url("https://public.speedoc.com/fonts/PlutoSans/PlutoSansRegular.woff") format("woff"),
    url("https://public.speedoc.com/fonts/PlutoSans/PlutoSansRegular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "PlutoSans";
  src: url("https://public.speedoc.com/fonts/PlutoSans/PlutoSansMedium.eot");
  src: url("https://public.speedoc.com/fonts/PlutoSans/PlutoSansMedium.eot?#iefix") format("embedded-opentype"),
    url("https://public.speedoc.com/fonts/PlutoSans/PlutoSansMedium.woff") format("woff"),
    url("https://public.speedoc.com/fonts/PlutoSans/PlutoSansMedium.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "PlutoSans";
  src: url("https://public.speedoc.com/fonts/PlutoSans/PlutoSansBold.eot");
  src: url("https://public.speedoc.com/fonts/PlutoSans/PlutoSansBold.eot?#iefix") format("embedded-opentype"),
    url("https://public.speedoc.com/fonts/PlutoSans/PlutoSansBold.woff") format("woff"),
    url("https://public.speedoc.com/fonts/PlutoSans/PlutoSansBold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "OpenSansSemiBold";
  src: url("https://public.speedoc.com/fonts/OpenSans/OpenSans-SemiBold.eot");
  src: url("https://public.speedoc.com/fonts/OpenSans/OpenSans-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("https://public.speedoc.com/fonts/OpenSans/OpenSans-SemiBold.woff") format("woff"),
    url("https://public.speedoc.com/fonts/OpenSans/OpenSans-SemiBold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* latin-ext */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Open Sans Light"), local("OpenSans-Light"),
    url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN_r8OXOhpKKSTj5PW.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Open Sans Light"), local("OpenSans-Light"),
    url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN_r8OUuhpKKSTjw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Open Sans Regular"), local("OpenSans-Regular"),
    url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFW50bf8pkAp6a.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Open Sans Regular"), local("OpenSans-Regular"),
    url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0bf8pkAg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"),
    url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UNirkOXOhpKKSTj5PW.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"),
    url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UNirkOUuhpKKSTjw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Open Sans Bold"), local("OpenSans-Bold"),
    url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN7rgOXOhpKKSTj5PW.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Open Sans Bold"), local("OpenSans-Bold"),
    url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN7rgOUuhpKKSTjw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local("Open Sans ExtraBold"), local("OpenSans-ExtraBold"),
    url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN8rsOXOhpKKSTj5PW.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local("Open Sans ExtraBold"), local("OpenSans-ExtraBold"),
    url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN8rsOUuhpKKSTjw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "HelveticaNeueLTProRoman";
  src: url("https://public.speedoc.com/fonts/HelveticaNeueLTPro-Roman/HelveticaNeueLTPro-Roman.eot");
  src: url("https://public.speedoc.com/fonts/HelveticaNeueLTPro-Roman/HelveticaNeueLTPro-Roman.eot?#iefix")
      format("embedded-opentype"),
    url("https://public.speedoc.com/fonts/HelveticaNeueLTPro-Roman/HelveticaNeueLTPro-Roman.woff") format("woff"),
    url("https://public.speedoc.com/fonts/HelveticaNeueLTPro-Roman/HelveticaNeueLTPro-Roman.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
