.tagInfoUM {
  border-radius: $sd-spacing-md;
  padding: $sd-spacing-xxs;
  background-color: #ededed;
  color: black;
  display: inline-grid;
  &.provider {
    background-color: #dfecf0;
  }
  .icon {
    grid-area: icon;
  }
  .title {
    grid-area: title;
    font-size: 12px;
    font-weight: 300;
  }
  .subtitle {
    grid-area: subtitle;
    font-size: $font-size-jarvis-xs;
    color: $text-color-captions;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .action {
    grid-area: action;
    padding: 0 $sd-spacing-xs;
    cursor: pointer;
    svg {
      vertical-align: middle;
      width: 10px;
    }
  }
  grid-template-areas:
    "icon title action"
    "icon subtitle action";
  grid-column-gap: 5px;
  align-items: center;
}
