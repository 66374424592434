@import "includes";
@import "modalEditor";

.noInternet {
  width: 100%;
  text-align: center;
  margin-top: 250px;
  text-transform: capitalize;
}

#dispatcher {
  background-color: #f5f5f5;

  #dateRangePicker {
    z-index: 5;
  }

  #columns {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    height: calc(100vh - 160px);
    h2 {
      font-family: "PlutoSans";
      font-size: $font-size-jarvis-sm;
      text-transform: uppercase;
      font-weight: normal;
      letter-spacing: normal;
      color: $sd-textcolor-black;
      opacity: 0.7;
      border-bottom: 1px solid #c3d9e5;
      line-height: 45px;
      padding-left: $sd-spacing-sm;
    }

    .columnHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: $sd-spacing-xs $sd-spacing-sm $sd-spacing-xs 0;

      &.nbRequests {
        color: black;
        text-align: center;
      }
      .count {
        padding: $sd-spacing-xxs $sd-spacing-xs + $sd-spacing-xxs;
        border-radius: 4px;
        color: white;
        font-size: $sd-text-sm;
        font-weight: 700;
        &.cancelled,
        &.draft {
          background-color: #dfecf0;
          color: #778cad;
        }
        &.accepted {
          background-color: #dfecf0;
          color: #778cad;
        }
        &.assigned {
          background-color: #e4f9c3;
          color: #82ad26;
        }
        &.inProgress {
          background-color: #efdfff;
          color: #6c63ff;
        }
        &.inTransit {
          background-color: #d7e7ff;
          color: #2377f9;
        }
        &.visitEnd {
          background-color: #fedfff;
          color: #df38fa;
        }
        &.completed {
          background-color: #cef7fa;
          color: #2aaab4;
        }
      }
    }

    .column {
      border-radius: 0 $sd-border-radius-sm 0 0;
      box-shadow: 2px 0px 8px 0 #D1D7D9;
      background-color: #ffffff;
      min-width: $sd-spacing-sm * 12;
      padding-top: $sd-spacing-xs;

      &.draft {
        z-index: 8;
        .card {
          .cardHeader {
            background-color: #e6e8eb;
            .bookingType,
            .price {
              color: #888888;
            }
            .price {
              border-left-color: #cccfd5;
            }
          }
        }
      }
      &.accepted {
        z-index: 7;
      }
      &.assigned {
        z-index: 6;
      }
      &.inTransit {
        z-index: 5;
      }
      &.inProgress {
        z-index: 4;
      }
      &.visitEnd {
        z-index: 3;
      }
      &.completed {
        z-index: 2;
      }
      &.cancelled {
        z-index: 1;
      }
    }
  }
}
