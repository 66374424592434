.inputCaption {
  .input {
    margin-bottom: 0;
    &::before {
      display: none;
    }
  }
  .caption {
    font-size: 10px;
    color: #4f657a;
    padding: $sd-spacing-xxs;
  }
}
