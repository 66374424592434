.requestUM {
  .headerUM {
    .title {
      color: #3ebebc;
      display: flex;
      align-items: center;

      &.edit {
        color: black;

        .back {
          margin-right: 8px;
          display: flex;
        }
      }

      .subtitle {
        margin-left: 8px;
        font-size: 12px;
        color: #6c6c6c;
        margin-top: 4px;
      }
    }

    .activityLogLink {
      flex-grow: 1;
      text-align: right;
      font-size: 12px;
    }

    svg {
      cursor: pointer;
    }
  }

  .buttonUM {
    font-weight: initial;
  }

  .specialty {
    margin: -16px 0 20px 16px;
  }

  .scheduleSection {
    display: flex;
    flex-direction: column;
    margin: 0 16px;
    .scheduleUM {
      font-weight: 600;
      margin-bottom: 6px;
    }
    .editAddressText {
      display: block;
    }
  }

  .provider {
    margin: 18px 16px 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .providerGroup {
      width: 100%;
      display: flex;
      &.edit svg {
        margin-right: $sd-spacing-sm;
      }
      svg {
        align-self: center;
      }
      .editProvider .text {
        gap: $sd-spacing-xs;
        flex-direction: column;
        padding: 0 0 0 $sd-spacing-xs;
        .avatarUM {
          margin-right: 6px;
        }
      }
      .timer {
        display: flex;
        width: 115px;
        height: 34px;
        background: #dfecf0;
        border-radius: 6px;
        justify-content: center;
        margin-left: auto;
        .title,
        .renderTime {
          width: 50%;
          display: flex;
          justify-content: center;
          align-self: center;
          font-family: Open Sans;
          font-style: normal;
          font-weight: 600;
        }
        .title {
          font-size: 14px;
          color: #778cad;
        }
        .renderTime {
          font-size: 20px;
          &.danger {
            color: #d30000;
          }
        }
      }
    }
    svg {
      margin-right: 20px;
    }
  }

  .userSection {
    display: flex;
    margin: 20px 16px;
    justify-content: space-between;
    .user {
      display: flex;
    }
    .userDetails {
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      span {
        font-weight: 600;
        &.light {
          font-weight: 300;
        }
      }
      a {
        color: #126ef8;
        text-decoration: underline;
      }

      .clickable {
        font-weight: 600;
        cursor: pointer;
      }
      .clickable:hover {
        text-decoration: underline;
      }
    }
    .userEdit {
      display: flex;
      width: 100%;
    }
  }

  .userBilling {
    display: flex;
    flex-direction: column;
    min-width: 154px;
    text-transform: capitalize;
    gap: 8px;
    span {
      text-transform: none;
    }
    .otherSpan {
      display: block;
    }
    svg {
      margin-right: 15px;
    }
    .paymentDetail {
      display: flex;
      flex-direction: column;
    }
    .accountNumber {
      font-family: "Open Sans";
      display: flex;
      font-size: 10px;
      letter-spacing: -0.1px;
      color: #6c6c6c;
      text-transform: capitalize;
      .caption {
        display: block;
      }
      &.invalid {
        color: red;
      }
    }

    &.edit {
      margin: 20px 16px;
      cursor: pointer;
      flex-direction: row;
      .accountNumber {
        font-size: 12px;
        color: black;
      }
      svg {
        margin-right: 0;
      }

      .corporatePaymentMethod {
        .tagUM {
          font-size: 10px;
          height: 19px;
          background-color: #1065e4;
          color: white;
          cursor: default;
          margin-right: 8px;
          text-transform: uppercase;
        }
      }
    }

    .freeCaption,
    .corporateCaption {
      display: flex;
      text-transform: none;
      .content {
        display: flex;
        flex-direction: column;
        .main {
          font-weight: 600;
          font-size: 12px;
        }
        .action {
          font-weight: 400;
          color: #ff5c8e;
        }
      }
    }

    .corporateCaption {
      .tagUM {
        font-size: 10px;
        height: 19px;
        font-weight: normal;
        background-color: #1065e4;
        color: white;
        cursor: default;
        text-transform: uppercase;
      }
      .action {
        font-size: 12px;
        margin-top: 2px;
      }
    }
  }

  .patientSection {
    display: flex;
    margin: 20px 16px;
    justify-content: space-between;
    .patient {
      display: flex;
      align-items: center;
      svg {
        margin-right: 20px;
      }

      .clickable {
        cursor: pointer;
      }
      .clickable:hover {
        text-decoration: underline;
      }
    }

    .patientBilling {
      display: flex;
      svg {
        margin-right: 15px;
      }
      .paymentDetail {
        display: flex;
        flex-direction: column;
        .accountNumber {
          font-family: "Open Sans";
          font-size: 10px;
          letter-spacing: -0.1px;
          color: #6c6c6c;
          text-transform: capitalize;
        }
      }
    }

    &.edit {
      opacity: 0.3;
    }
  }

  .providerInfo {
    display: flex;
    flex-direction: column;
    gap: $sd-spacing-xs;
    &.edit {
      cursor: pointer;
    }
    .providerInfoItem {
      display: flex;
      align-items: center;
      .avatarUM {
        margin-right: 6px;
      }
      img {
        margin-left: 8px;
      }
      .providerDetail {
        .name {
          display: flex;
          text-wrap: nowrap;
          .primaryInfo {
            margin-left: 16px;
            font-weight: 600;
          }
        }
        .type {
          font-size: 10px;
          color: #6c6c6c;
        }
      }
      .seenStatus {
        margin-left: 8px;
      }
    }
  }

  .editProvider .providerInfoItem {
    display: flex;
    align-items: center;
    .avatarUM {
      margin-right: 6px;
    }
    img {
      margin-left: 8px;
      display: initial;
    }
    .providerDetail {
      .name {
        display: flex;
        .primaryInfo {
          margin-left: 16px;
          font-weight: 600;
        }
      }
      .type {
        font-size: 10px;
        color: #6c6c6c;
      }
    }
    .seenStatus {
      margin-left: 8px;
    }
  }

  .symptomsUM {
    display: flex;
    align-items: center;
    margin: 20px 16px;
    font-weight: 600;

    svg {
      margin-right: 20px;
      min-width: 22px;
      transform: rotate(90deg);
      path:first-child {
        fill: #979797;
      }
    }

    &.edit {
      opacity: 0.3;
    }
  }

  .scheduleStart {
    margin: $sd-spacing-sm $sd-spacing-sm $sd-spacing-xxs $sd-spacing-sm;
    display: flex;
    align-items: center;
    gap: $sd-spacing-xs;

    .linkGray {
      margin-left: $sd-spacing-xs;
    }

    .buttonUM.primary {
      font-size: inherit;
      min-width: unset;
    }
  }

  .requestedAddress {
    display: flex;
    margin: 20px 16px 0 16px;
    align-items: center;

    svg {
      margin-right: 20px;
      min-width: 22px;
    }

    .light {
      display: block;
      color: $text-color-yellow;
    }
  }

  .destinationAddress {
    display: flex;
    margin: 10px 16px 20px 16px;
    align-items: center;

    svg {
      margin-right: 20px;
      min-width: 22px;
      path {
        fill: #ff5c8e;
      }
    }

    .light {
      display: block;
      color: $text-color-yellow;
    }
  }

  .notes {
    display: flex;
    align-items: center;
    margin: 20px 16px;
    .preWrap {
      white-space: pre-wrap;
    }

    svg {
      margin-right: 20px;
      min-width: 22px;
    }

    &.edit {
      opacity: 0.3;
    }
  }

  .clinicalNotes {
    &.edit {
      padding: 0 $sd-spacing-sm;
    }
  }

  .jarvisNotes {
    &.edit {
      padding: 0 $sd-spacing-sm;
    }
  }

  .emr {
    display: flex;
    align-items: center;
    gap: $sd-spacing-sm;
  }

  .feesTable {
    margin: $sd-spacing-sm $sd-spacing-sm 0 $sd-spacing-sm;

    table {
      border-collapse: collapse;
      width: 100%;
      tr {
        height: 40px;
        border-bottom: 1px solid #eeeeee;
        &:last-child {
          border: none;
        }
        td {
          padding: 10px 0;
        }
      }
      thead {
        th {
          font-family: "Open Sans";
          font-size: 10px;
          font-weight: 100;
          text-transform: uppercase;
          color: #979797;
          text-align: right;
        }
        th:first-child {
          text-align: left;
          width: 200px;
        }
        th:nth-child(2) {
          width: 30px;
        }
        th:nth-child(3) {
          width: 40px;
        }
        th:nth-child(4) {
          width: 40px;
        }
        th:nth-child(5) {
          width: 40px;
        }
      }
      tbody {
        td {
          text-align: right;
          vertical-align: top;
        }
        td:first-child {
          text-align: left;
        }
      }
    }

    &.edit {
      opacity: 0.3;
    }
  }

  .addonItems {
    .text {
      justify-content: start;
      gap: $sd-spacing-xs;
      display: block;
      .tagInfoUM {
        margin: $sd-spacing-xxs;
        .subtitle {
          .price {
            font-size: $font-size-jarvis-xs;
            color: $sd-green-primary;
          }
        }
      }
    }
  }

  .footerUM {
    .footerLine {
      margin-top: 0px;
    }

    .caption {
      margin-bottom: $sd-spacing-sm;
      color: $text-color-captions;
      word-wrap: break-word;
      &.right {
        text-align: right;
      }
      .notCharged {
        color: #1065e4;
      }
      .paymentCharged {
        .price {
          display: contents;
          font-size: 12px;
          font-weight: 400;
          font-family: "Open Sans";
          color: #a5a5ad;
        }
      }
      .cardCharged {
        a {
          color: #1065e4;
          text-decoration: underline;
        }
        .cardName {
          text-transform: capitalize;
        }
      }
      .corporateCharged {
        color: #a9a9a9;
        .tagUM {
          font-size: 10px;
          height: 19px;
          background-color: #1065e4;
          color: white;
          cursor: default;
          margin-right: 0px;
          text-transform: uppercase;
        }
        span {
          color: #1065e4;
        }
      }
      .redText {
        color: #ff5c8e;
      }
    }

    hr {
      border: none;
      border-top: 1px solid #eee;
    }

    .invoiceCaption {
      display: flex;
      flex-direction: column;

      .firstRow {
        display: flex;
        align-items: center;
        .grandTotal {
          flex-grow: 1;
          text-align: left;
        }

        .tagUM {
          background-color: #4ecc00;
          padding: 2px $sd-spacing-xxs;
          font-size: 12px;
          color: white;
          cursor: default;
          &.unpaid {
            background-color: #e24646;
          }
          &.partial {
            background-color: #e28e46;
          }
        }
        .price {
          text-align: right;
        }
      }
      .secondRow {
        display: flex;
        justify-content: space-between;
        .partialInfo {
          color: red;
          .price {
            display: contents;
            font-size: 12px;
            font-weight: 400;
            font-family: "Open Sans";
            color: red;
          }
        }
      }
    }
  }
}
