.timeBlockForm {
  .MuiPaper-root {
    overflow: visible;
  }

  .wrapper {
    font-size: 12px;
    padding: 16px;
    height: auto;
    width: auto;
    top: auto;
    left: auto;
    min-width: 0;
    border: 1px solid #c3d9e5;
    transform: none;
  }

  .header {
    display: flex;
    align-items: center;

    .title {
      font-size: 12px;
      flex-grow: 1;
    }

    .iconWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      border-radius: 4px;
      &:hover {
        background: #e7edf4;
      }
    }

    .iconButton {
      cursor: pointer;
    }
    .iconGap {
      margin-left: 8px;
    }
  }

  .typeToggle {
    display: flex;
    margin-top: 16px;
    padding: 4px;
    border: 1.5px solid #eeeeee;
    border-radius: 4px;
    background-color: $grey-primary;

    button {
      margin-bottom: 0;
      height: 24px;
      font-size: 12px;
      font-weight: 600;
      border-radius: 4px;
      background-color: $grey-primary;
      color: $grey-secondary;
      flex-grow: 1;
    }

    .roster {
      &.active {
        background: white;
        color: $button-group-blue;
      }
    }
  }

  .serviceList {
    .serviceOption {
      margin-top: 16px;
      display: flex;
      align-items: center;

      > .text {
        font-size: 12px;
        flex-grow: 1;
        margin-left: 18px;
      }
      .checkbox {
        height: 18px;
        width: 18px;

        .disabled {
          &.containerCheckBoxUM {
            cursor: default;
          }
          .checkmark {
            background-color: #eeeeee;
          }
        }
      }
    }
  }

  .durationSelect {
    margin-top: 16px;
    display: flex;
    align-items: center;

    .dateTimePicker {
      display: flex;
      align-items: center;
      column-gap: 8px;
      margin-left: 18px;
    }

    input {
      padding: 4px;
      width: 65px;
    }
    .timePicker {
      svg {
        height: 6px;
        width: 10px;
        top: 10px;
        path {
          fill: white;
        }
      }
    }
  }

  .footer {
    margin-top: 16px;
    text-align: right;

    .confirmButton {
      margin: 0;
      padding: 0;
      height: auto;
      background-color: inherit;
      color: #15bfcc;
      font-size: 12px;
      font-weight: 600;

      &.editMode {
        padding: 8px;
        font-size: 14px;
        background-color: #00c3b5;
        color: white;
        border-radius: 6px;
      }
    }
  }

  svg {
    height: 16px;
    width: 16px;
    path {
      fill: #9bb0c4;
    }
  }

  hr {
    margin: 12px 0px 8px 0px;
    background-color: #eeeeee;
    height: 1px;
    border: 0;
  }
}
