.triage {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: $sd-spacing-xxs $sd-spacing-xs;
  font-family: "HelveticaNeueLTProRoman";
  font-size: $font-size-xs;
  color: #85858b;

  .switch {
    cursor: pointer;
    position: relative;
    width: 47px;
    height: 24px;
    border-radius: 14px;
    transition: background-color 100ms ease-out;
    z-index: 1;
    box-shadow: 2px 2px $sd-spacing-xs 0 #a7b1bf, -5px -3px $sd-spacing-xs 0 #ffffff, inset 2.5px 2.5px 13px 0 #d9e2e7;
    background-color: #f9fdff;
    margin-left: $sd-spacing-sm;

    &.on:before {
      background: $sd-green-primary;
    }

    &:before {
      border: 2px solid white;
      -webkit-filter: blur(1px);
      filter: blur(1px);
      content: "";
      width: 47px;
      height: 24px;
      border-radius: 14px;
      position: absolute;
    }
  }

  .toggle-button {
    position: relative;
    width: 25px;
    height: 25px;
    border: double 2px transparent;
    border-radius: $sd-spacing-xxl;

    cursor: pointer;

    &.position-left {
      -webkit-transform: translateX(0px);
      transform: translateX(-2px);
      background-image: linear-gradient(white, white), radial-gradient(circle at top left, #ff993f, #ff993f);
      background-origin: border-box;
      background-clip: content-box, border-box;
    }

    &.position-right {
      -webkit-transform: translateX(20px);
      transform: translateX(22px);
      background-image: linear-gradient(white, white), radial-gradient(circle at top left, #66f1e6, #4bc9ba);
      background-origin: border-box;
      background-clip: content-box, border-box;
    }
  }
}
