.corporatePage {
  padding: $sd-spacing-xl;
  > .header {
    display: flex;
    .title {
      flex-grow: 1;
      font-size: 20px;
    }
    .filter {
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      align-self: flex-end;
      margin-right: $sd-spacing-sm;
      .search {
        position: relative;
        input {
          padding: 0 $sd-spacing-lg;
        }
        .filterSearchIcon {
          background-color: transparent;
          padding: 12px;
          border-radius: 0;
          position: absolute;
          top: 12px;
          left: 12px;
          width: 25px;
          height: 25px;
          z-index: 2;
          background-repeat: no-repeat;
          background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMS4zNzQ4IDkuMjYyNjFMMTMuNzQzNCAxMS42MzEyQzE0LjA4NTUgMTEuOTczMyAxNC4wODU1IDEyLjUyNjMgMTMuNzQzNCAxMi44Njg0TDEyLjg2ODQgMTMuNzQzNEMxMi42OTc4IDEzLjkxNCAxMi40NzM4IDEzLjk5OTggMTIuMjQ5OCAxMy45OTk4QzEyLjAyNTggMTMuOTk5OCAxMS44MDE4IDEzLjkxNCAxMS42MzEyIDEzLjc0MzRMOS4yNjI2MSAxMS4zNzQ4QzguMzQzODcgMTEuOTI2OSA3LjI3Mjg5IDEyLjI0OTggNi4xMjQ5IDEyLjI0OThDMi43NDgzMyAxMi4yNDk4IDAgOS41MDIzNSAwIDYuMTI0OUMwIDIuNzQ3NDYgMi43NDgzMyAwIDYuMTI0OSAwQzkuNTAxNDggMCAxMi4yNDk4IDIuNzQ3NDYgMTIuMjQ5OCA2LjEyNDlDMTIuMjQ5OCA3LjI3MjAxIDExLjkyNjEgOC4zNDM4NyAxMS4zNzQ4IDkuMjYyNjFaTTEuNzUgNi4xMjQ5M0MxLjc1IDguNTQwNzcgMy43MDkwOSAxMC40OTk5IDYuMTI0OTMgMTAuNDk5OUM4LjU0MDc3IDEwLjQ5OTkgMTAuNDk5OSA4LjU0MDc3IDEwLjQ5OTkgNi4xMjQ5M0MxMC40OTk5IDMuNzA5MDkgOC41NDA3NyAxLjc1IDYuMTI0OTMgMS43NUMzLjcwOTA5IDEuNzUgMS43NSAzLjcwOTA5IDEuNzUgNi4xMjQ5M1oiIGZpbGw9IiM2MkM5RTMiLz4KPC9zdmc+Cg==");
        }
      }
    }
  }
  > .content {
    table.corporateList {
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      width: 100%;
      border-radius: 10px;
      border: solid 1px #e7edf4;
      padding: 0 $sd-spacing-sm;
      color: $grey-secondary;
      th,
      td {
        border-bottom: solid 1px #e7edf4;
        font-weight: normal;
        font-size: 14px;
        text-align: left;
        padding: $sd-spacing-sm;
        .avatar {
          display: inline-flex;
          align-items: center;
          img {
            width: 26px;
            height: 26px;
            border-radius: 6px;
            object-fit: cover;
            margin-right: $sd-spacing-xs;
          }
        }
        .tag {
          font-size: 10px;
          height: 19px;
          text-transform: uppercase;
          background-color: #1065e4;
          color: white;
          cursor: default;
        }
        &.actions {
          .green {
            cursor: not-allowed;
            color: #62e3c3;
            text-align: center;
            width: 75px;
            display: inline-block;
          }
          .blue {
            cursor: pointer;
            color: #13a5f8;
          }
        }
        &.status {
          div {
            position: relative;
            text-transform: capitalize;
            padding: $sd-spacing-xxs;

            &::before {
              position: absolute;
              content: " ";
              top: 8px;
              left: -8px;
              width: $sd-spacing-xs;
              height: $sd-spacing-xs;
              border-radius: 50%;
            }
            &.pending::before {
              background-color: #13a5f8;
            }
            &.inactive::before {
              background-color: #eb986a;
            }
            &.active::before {
              background-color: #62e3c3;
            }
          }
        }
        &:nth-child(1) {
          width: 10px;
        }
        &:nth-child(2) {
          width: 300px;
        }
      }
      tbody {
        display: block;
        max-height: calc(100vh - 300px);
        overflow: auto;
        @include scrollbar();
        &.disableScroll {
          overflow: hidden;
        }
        .loader {
          > div {
            margin: auto;
          }
        }
        .noData {
          padding: $sd-spacing-sm;
          font-size: 14px;
          color: $text-color-captions;
        }
      }
      thead,
      tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
        &:last-child {
          td {
            border-bottom: none;
          }
        }
      }
    }
  }
}
