.eRoster {
  user-select: none;

  .topFilters {
    display: flex;
    position: sticky;
    top: 0px;
    margin-top: 20px;
    padding: 4px 40px;
    align-items: center;
    z-index: 6;
    background: white;

    @import "eRosterDatePicker";
  }
  .timelineContainer > .timeline.inViewLoader ~ .timeline.inViewLoader {
    display: none;
  }

  .timelineContainer {
    padding: 0px 40px 24px;
    margin-top: 16px;

    @import "eRosterTimeline";
  }
}
