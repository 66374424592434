.statusContainerUM {
  position: relative;
  margin-left: auto;
  .statusTagUM {
    border-radius: 30px;
    display: inline-block;
    padding: 2px $sd-spacing-xs;
    text-transform: uppercase;
    font-weight: 600;
    &.dropDown {
      border: 2px solid #ffffff;
      cursor: pointer;
    }
    &.open {
      box-shadow: 0 0 0 2px #9dc1f8;
    }
    &.draft {
      background-color: #e0e2e4;
      color: #868e95;
    }
    &.cancelled,
    &.discharged,
    &.red {
      background-color: rgba(228, 16, 79, 0.15);
      color: #e4104f;
    }
    &.accepted,
    &.grey {
      background-color: rgba(119, 140, 173, 0.15);
      color: #778cad;
    }
    &.assigned,
    &.lime {
      background-color: rgba(130, 173, 38, 0.15);
      color: #82ad26;
    }
    &.inProgress,
    &.purple {
      background-color: rgba(107, 99, 255, 0.15);
      color: #6c63ff;
    }
    &.inTransit,
    &.blue {
      background-color: rgba(35, 119, 249, 0.15);
      color: #2377f9;
    }
    &.visitEnd {
      background-color: #fedfff;
      color: #df38fa;
    }
    &.completed,
    &.green {
      background-color: rgba(42, 170, 180, 0.15);
      color: #2aaab4;
    }
    .caret {
      width: 0;
      height: 0;
      display: -webkit-inline-box;
      border: 4px solid transparent;
      border-top-color: #778cad;
      border-bottom-width: 2px;
    }
  }

  .dropdownStatusTagUM {
    &.show {
      display: block;
    }
    position: absolute;
    top: 35px;
    display: none;
    z-index: 1;
    min-width: 110px;
    border-radius: $sd-border-radius-xs;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    overflow: auto;
    background-color: #ffffff;
    li {
      cursor: pointer;
      margin: 5px 0px 5px 0px;
      padding: 2px $sd-spacing-xs;
      &:hover {
        background-color: rgba(218, 218, 218, 0.2);
      }
      &.active {
        cursor: default;
        background-color: rgba(218, 218, 218, 0.2);
      }
    }
  }
}
