.placeFormDialog {
  .btnCreate {
    color: #ffffff;
    font-weight: 600;
    text-transform: capitalize;
    background: #00c3b5;
    border-radius: 6px;

    &:hover {
      color: #ffffff;
      background: #00c3b5;
    }
  }

  .addressAutocompleteUM {
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 400;
    .googleMapAutocompleteInput {
      border: solid 1px #c7c7c7;
      font-family: Open Sans;
      font-size: 12px;
      font-weight: 400;
      border-radius: 4px;
      &::placeholder {
        color: #a7a7a7;
        font-family: Open Sans;
        font-size: 12px;
        font-weight: 400;
        opacity: 1;
      }
      &:focus {
        border: solid 2px #1976d2;
      }
    }

    .suggestionContainer {
      width: 88%;
    }

    &.error {
      .googleMapAutocompleteInput {
        border: solid 2px #d32f2f;
      }
    }
  }

  .autoCompleteUM {
    .inputUM {
      input {
        height: 34px;
        border: solid 1px #c7c7c7;
        border-radius: 4px;

        &::placeholder {
          color: #a7a7a7;
          font-family: Open Sans;
          font-size: 12px;
          font-weight: 400;
          opacity: 1;
        }

        &:focus {
          border: solid 2px #1976d2;
        }
      }
    }

    .list {
      left: -204px;
      min-width: 460px;
      max-height: 175px;
    }
  }
}
