.cellStatus {
  &:before,
  &:after {
    display: none;
  }

  &.status {
    cursor: pointer;
    font-weight: bolder;
    &.pending {
      color: #778cad;
      background-color: #dfecf0;
    }
    &.completed {
      color: #2aaab4;
      background-color: #cef7fa;
    }
    &.cancelled {
      color: #e4104f;
      background-color: rgba(228, 16, 79, 0.15);
    }
  }
}
