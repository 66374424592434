.scheduleUM {
  position: relative;

  .react-datepicker-wrapper {
    width: 100%;
  }

  .icon svg {
    margin-right: 12px;
    vertical-align: middle;
  }

  .addressAutocompleteUM {
    input {
      font-size: 12px;
    }
    .suggestionContainer {
      width: 92%;
    }
  }

  .scheduleDateTimePickerTextField {
    & .MuiOutlinedInput-root {
      & fieldset {
        border-color: #e7edf4;
      }
      &:hover fieldset {
        border-color: #e7edf4;
      }
      &.Mui-disabled fieldset {
        border-color: #e7edf4;
      }
      &.Mui-focused fieldset {
        border: 1px solid #2ed9e5;
      }
      &.Mui-error fieldset {
        border-color: #ff1f4a;
      }
    }
    input {
      font-size: 12px;
    }
  }
}
