.timeline {
  $cell-color: #f7fafc;
  $border-color: #dadcdf;
  $border-default: 1px solid $border-color;

  @import "eRosterProviderAssignment";

  table {
    width: 100%;
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 600;
    thead {
      @import "CurrentTimeCursor";
      // first row is displaying the hours
      tr:first-child {
        position: sticky;
        top: 51px;
        z-index: 4;
        height: auto;
        background: white;
        color: #979797;
        border: none;

        td {
          text-align: right;
          border: none;

          span {
            display: inline-block;
            position: relative;
            right: -4px;
            background: white;
            padding: 4px 0px;
          }
        }
      }
      // second row only shows a little vertical borders
      tr:nth-child(3) {
        position: sticky;
        top: 76px;
        height: 8px;
        background: white;
        z-index: 4;
        box-shadow: inset 0 -1px 0 #dadcdf;
        td {
          border-right: $border-default;
          height: inherit;
          z-index: 4;
        }
      }

      // third row have different background colors
      tr:nth-child(4) {
        border-bottom: $border-default;
        td {
          border-right: $border-default;
          background: $cell-color;
        }
        td:first-child {
          background: white;
          border-left: $border-default;
          border-right: $border-default;
          cursor: default;

          .dateText {
            font-size: 16px;
          }

          .addProvider {
            margin: 0;
            padding: 0;
            height: auto;
            background-color: inherit;
            color: #00c3b5;
            font-size: 14px;
            font-weight: 600;
            line-height: 22px;
          }

          .optionButton {
            display: flex;
            position: relative;
            flex-direction: column;
            border-radius: 4px;
            &:hover,
            &.open {
              cursor: pointer;
              background: #e7ecf4;
            }
            .threeDots {
              display: flex;
              width: 24px;
              height: 24px;
              padding: 4px;
              align-items: center;
            }
            .options {
              position: absolute;
              top: 26px;
              left: 0px;
              z-index: 3;
              padding: 6px 0px;
              width: max-content;
              background: #ffffff;
              box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
              border-radius: 4px;
              .optionList {
                display: flex;
                font-weight: normal;
                min-width: 120px;
                padding: 6px;
                flex-direction: row;
                align-items: center;
                &:hover {
                  background: #e7ecf4;
                }
              }
            }
          }
        }
      }
    }

    tbody {
      // for the rest of rows
      tr {
        height: 64px;
        background: $cell-color;
        border-bottom: $border-default;
        vertical-align: top;

        td:first-child {
          min-width: 216px;
          max-width: 216px;
          background: white;
          border-left: $border-default;
          cursor: grab;
        }
        td {
          width: 3.6%;
          min-width: 24px;
          border-right: $border-default;
          height: inherit;
          cursor: pointer;

          @import "eRosterTimeBlock";
          @import "eRosterService";

          .cell {
            display: inline-block;
            height: 100%;
            width: 100%;

            &.highlighted {
              background-color: #464bce80;
            }
          }
        }
      }
    }
  }

  .providerCard {
    display: flex;
    height: 100%;
    padding: 8px;
    align-items: center;
    gap: 8px;

    .description {
      flex-grow: 1;
      min-width: 0;
      font-weight: 400;

      .fullName {
        color: #141417;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .typeContainer {
        display: flex;
        margin-top: 4px;

        .typeText {
          flex-grow: 1;
          color: #68686a;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .serviceIcons {
          display: flex;
          align-items: center;
          gap: 0px 4px;

          svg {
            height: 8px;

            path {
              fill: #9bb0c4;
            }
          }
        }
      }
    }
  }

  &.inViewLoader {
    display: flex;
    padding: 50px 0;
    justify-content: center;
    height: 200px;
  }
}
