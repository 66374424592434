.activityLogUM {
  table {
    border-collapse: collapse;
    width: 100%;
    tr {
      height: 40px;
      border-bottom: 1px solid #eeeeee;
      th,
      td {
        padding: $sd-spacing-xs;
        text-transform: capitalize;
        .caption {
          color: #979797;
        }
        &:first-child {
          padding-left: $sd-spacing-lg;
          width: 30%;
        }
        &:nth-child(2) {
          width: 25%;
        }
      }
    }
    thead {
      th {
        font-family: inherit;
        font-size: 10px;
        text-transform: uppercase;
        color: #979797;
        text-align: left;
        font-weight: 100;
        border-bottom: 1px solid #eee;
      }
    }
    tbody {
      td {
        text-align: left;
      }
    }
  }
}
