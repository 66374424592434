.toggleDispatcher {
  display: flex;
  border-radius: 12px;
  box-shadow: 3px 3px 10px 0 #e7eef3, -3px -3px 10px 0 #ffffff, inset 4px 4px 13px 0 #e5eaf1,
    inset -4px -4px 3px 0 #ffffff;
  border: solid 2px #ffffff;
  background-color: #f7fafc;
  padding: 1px;

  div {
    line-height: 1.57;
    font-size: 14px;
    padding: $sd-spacing-xs $sd-spacing-sm;
    color: #8fa0aa;
    cursor: pointer;
    &.active {
      border-radius: 12px;
      box-shadow: 1px 1px 10px 0 rgba(165, 175, 192, 0.4);
      background-color: #f9fdff;
      color: $sd-textcolor-green;
      cursor: default;
    }
  }

  &.disabled div {
    cursor: not-allowed;
  }
}
