.taskManager {
  .filters {
    .title {
      color: #141417;
      font-size: 20px;
    }

    .searchField {
      width: 240px;
    }
  }
  .MuiCircularProgress-root {
    z-index: 1;
  }
  .MuiDataGrid-cell {
    cursor: pointer;
  }
}
