.corporateDetails {
  .header {
    display: flex;
    justify-content: space-between;
    padding: $sd-spacing-lg $sd-spacing-lg 0 $sd-spacing-lg;

    .breadCrumb {
      display: flex;
      font-size: 20px;
      align-items: center;
      padding-bottom: $sd-spacing-md;
      img {
        display: inline;
        transform: rotate(180deg);
        margin: 0 $sd-spacing-sm;
        height: 20px;
      }
    }
    .actions {
      .edit {
        margin-left: $sd-spacing-sm;
      }
    }
  }
  .content {
    display: flex;
    padding: 0 $sd-spacing-lg $sd-spacing-lg $sd-spacing-lg;

    .info {
      border-radius: 10px;
      border: solid 1px #e7edf4;
      padding: $sd-spacing-md;
      font-size: 14px;
      margin-right: $sd-spacing-lg;
      width: 500px;
      .nameAvatar {
        display: flex;
        margin-bottom: $sd-spacing-md;
        .name {
          .corporateName {
            font-size: 16px;
            font-weight: bold;
          }
          .corporateGroupName {
            font-size: 12px;
            font-weight: bold;
          }
          .corporateDate {
            font-size: 10px;
            color: $text-color-captions;
          }
          .tag {
            background-color: #1065e4;
            color: white;
            text-transform: uppercase;
          }
        }
        .avatar {
          margin-right: $sd-spacing-sm;
          img {
            width: 80px;
            height: 80px;
            object-fit: cover;
            border-radius: 10px;
          }
        }
      }
      .billingAddress {
        margin-bottom: $sd-spacing-md;
        .title {
          display: flex;
          align-items: center;
          font-weight: bold;
        }
      }
      .paymentMethod {
        margin-bottom: $sd-spacing-md;
        .title {
          display: flex;
          align-items: center;
          font-weight: bold;
        }
        .caption {
          font-size: 10px;
          color: $text-color-captions;
        }
        .defaultMethod {
          text-transform: capitalize;
        }
      }
      .contacts {
        margin-bottom: $sd-spacing-md;
        text-transform: capitalize;
        .title {
          display: flex;
          align-items: center;
          font-weight: bold;
        }
        .list {
          div:not(:last-child) {
            margin-bottom: $sd-spacing-xs;
          }
          .contactEmail {
            text-transform: lowercase;
          }
        }
      }
      .size {
        .title {
          display: flex;
          align-items: center;
          font-weight: bold;
        }
      }
    }
    .rateCard {
      border-radius: 10px;
      background-color: #eef9ff;
      font-size: 14px;
      width: 500px;
      padding: $sd-spacing-sm;
      .title {
        font-size: 16px;
        font-weight: bold;
        padding: $sd-spacing-xs $sd-spacing-sm;
      }
      .priceItem {
        padding: $sd-spacing-xs $sd-spacing-sm;
        &:not(:first-child) {
          border-top: 1px solid #eeeeee;
        }
        display: flex;
        justify-content: space-between;
        .price {
          font-size: 14px;
        }
      }
    }
  }
}
