.textToggleUM {
  display: flex;
  align-items: center;

  &.disabled {
    opacity: 0.4;
    cursor: initial;
  }

  .switch {
    cursor: pointer;
    position: relative;
    width: 140px;
    height: 30px;
    border-radius: 8px;
    transition: background-color 100ms ease-out;
    z-index: 1;
    background-color: #e7edf4;
    display: flex;
    align-items: center;

    &.disabled {
      cursor: default;
    }

    &:before {
      content: "";
      width: 140px;
      height: 30px;
      border-radius: 8px;
      position: absolute;
    }

    .optionRight {
      position: absolute;
      left: 59%;
    }

    .optionLeft {
      padding-left: 24px;
    }

    .optionLeft,
    .optionRight {
      font-family: "Open Sans";
      font-size: 12px;
      text-align: center;
      color: #4f657a;
    }

    .optionRight,
    .optionLeft,
    .toggleText {
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }

  .toggle-button {
    position: relative;
    width: 70px;
    height: 22px;
    border-radius: 6px;
    top: 0px;
    cursor: pointer;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
    background-image: linear-gradient(white, white), radial-gradient(circle at top left, #ff993f, #ff993f);

    &.disabled {
      cursor: default;
    }

    .toggleText {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 2px;
      font-family: "Open Sans";
      font-size: 12px;
      font-weight: 600;
      color: #15bfcc;
    }

    &.position-left {
      -webkit-transform: translateX(5px);
      transform: translateX(3px);
      background-origin: border-box;
      background-clip: content-box, border-box;
    }

    &.position-right {
      -webkit-transform: translateX(14px);
      transform: translateX(16px);
      background-origin: border-box;
      background-clip: content-box, border-box;
    }
  }
}
