.radioButtonContainerUM {
  display: flex;
  flex-direction: column;
  gap: $sd-spacing-sm;
  .radioButtonUM {
    display: grid;
    align-items: center;
    grid-column-gap: 20px;
    grid-template-columns: 20px auto;
    .icon {
      border: 2px solid white;
      background-color: white;
      box-shadow: 0 0 0 2px #c4c4c4;
      border-radius: 50%;
      width: 14px;
      height: 14px;
      grid-area: icon;

      &.selected {
        background-color: #00c3b5;
      }
    }
    .content {
      display: inline-flex;
      grid-area: content;
    }
    &.right {
      grid-template-areas: "icon content";
    }
    &.left {
      grid-template-areas: "content icon";
    }
  }
}
