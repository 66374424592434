.mediaUploader {
  margin: $sd-spacing-md - $sd-spacing-xxs $sd-spacing-sm;
  display: flex;
  align-items: center;

  .icon {
    margin-right: 20px;
    min-width: 22px;
  }

  .inputGroup {
    display: flex;
    flex-direction: column;
    label {
      cursor: pointer;
      color: #0c7cd5;
      font-weight: 700;
    }
    .error {
      color: #e4104f;
    }
  }

  .attachments {
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: $sd-spacing-xs;
    padding-top: 10px;
    .mediaGroup {
      cursor: pointer;
      position: relative;
      &:hover {
        .hover {
          display: flex;
        }
      }
      &.pdf {
        .pdfThumbnail {
          border: 1px solid #f7f7f7;
        }
        > .mediaInformation {
          position: absolute;
          top: $sd-spacing-xs;
          left: $sd-spacing-xs;
        }
      }
    }

    .actions {
      display: flex;
      justify-content: space-between;
      svg {
        height: 18px;
        path {
          fill: #616161;
        }
      }
    }

    .mediaInformation {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      .name {
        font-size: 11px;
      }
      .size {
        font-size: 11px;
        color: #979797;
      }
    }

    img,
    .hover {
      border-radius: 3px;
      width: 100%;
      height: 77px;
      object-fit: cover;
    }
    .hover {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      flex-direction: column;
      padding: $sd-spacing-xs;
      background-color: rgba(255, 255, 255, 0.95);
      cursor: pointer;
    }
  }
  .modal {
    background-color: rgba(0, 0, 0, 0.4);
  }
}

.previewMediaModal {
  &.pdf {
    .content {
      position: relative;
      .pdfLoader {
        height: 80vh;
        width: 350px;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .pdfNavigation {
        font-family: inherit;
        position: absolute;
        text-align: center;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        font-family: "Open Sans";
        font-size: 14px;
        svg {
          margin: 0 $sd-spacing-xs;
          cursor: pointer;
          &.hidden {
            visibility: hidden;
          }
          &.next {
            transform: rotate(-90deg);
          }
          &.prev {
            transform: rotate(90deg);
          }
          path {
            fill: black;
          }
        }
      }
    }
  }
  &.modal {
    background-color: rgba(0, 0, 0, 0.4);
  }
  display: flex;
  background-color: transparent;
  justify-content: center;
  box-shadow: none;
  .cross {
    cursor: pointer;
    margin-left: 12px;
    margin-top: $sd-spacing-xs;
    svg {
      path:first-child {
        fill: #ffffff;
      }
    }
  }
  .content {
    display: flex;
    justify-content: center;
    img {
      max-width: 1000px;
      max-height: 85vh;
      border-radius: $sd-spacing-xs;
    }
  }
}
