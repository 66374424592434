.googleMapAutocompleteInput {
  border-radius: 6px;
  border: solid 1.5px #e7edf4;
  padding: $sd-spacing-xs;
  width: 100%;
  outline: none;
}

.suggestionContainer {
  display: none;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);
  position: absolute;
  width: 100%;
  z-index: 3;
  &.opened {
    display: block;
  }
  .suggestionItem {
    cursor: pointer;
    padding: $sd-spacing-xs;
    &:hover {
      background-color: #f1f3f4;
    }
    .mainText {
      line-height: 1.58;
      color: black;
    }
    .secondaryText {
      line-height: 1.58;
      font-size: $font-size-jarvis-xs;
      color: #6c6c6c;
    }
  }
}
