.headerUM {
  display: flex;
  padding: $sd-spacing-sm 0;
  align-items: center;
  gap: $sd-spacing-sm;
  margin: 0 $sd-spacing-sm;
  .title {
    display: flex;
    align-items: flex-end;
    flex-grow: 1;
    font-family: "PlutoSans", "Helvetica";
    font-size: 18px;
    color: black;
  }
  svg {
    cursor: pointer;
  }
  .close {
    width: 20px;
    height: 20px;
    path {
      fill: #979797;
    }
  }
}
