.toggleUM {
  display: flex;
  align-items: center;

  &.disabled {
    opacity: 0.4;
    cursor: initial;
  }

  .switch {
    cursor: pointer;
    position: relative;
    width: 50px;
    height: 30px;
    border-radius: 8px;
    transition: background-color 100ms ease-out;
    z-index: 1;
    background-color: #e7edf4;
    margin-left: 13px;

    &.disabled {
      cursor: default;
    }

    &.on:before {
      background: $sd-green-primary;
    }

    &:before {
      content: "";
      width: 50px;
      height: 30px;
      border-radius: 8px;
      position: absolute;
    }
  }

  .toggle-button {
    position: relative;
    width: 24px;
    height: 22px;
    border-radius: 6px;
    top: 4px;
    cursor: pointer;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);

    &.disabled {
      cursor: default;
    }

    &.position-left {
      -webkit-transform: translateX(5px);
      transform: translateX(3px);
      background-image: linear-gradient(white, white), radial-gradient(circle at top left, #ff993f, #ff993f);
      background-origin: border-box;
      background-clip: content-box, border-box;
    }

    &.position-right {
      -webkit-transform: translateX(20px);
      transform: translateX(22px);
      background-image: linear-gradient(white, white), radial-gradient(circle at top left, #66f1e6, #4bc9ba);
      background-origin: border-box;
      background-clip: content-box, border-box;
    }
  }
}
