button.primary {
  background-color: #00c3b5;
  box-shadow: none;
}

button.secondary {
  background-color: transparent;
}

.todayButton {
  width: 72px;
  height: 42px;
  background: #f9fdff;
  box-shadow: 1px 0px 8px rgba(192, 200, 212, 0.398547);
  border-radius: 12px;
  font-size: 14px;
  line-height: 28px;
  color: #a5a5ac;
  margin: 0px 16px 0px 0px;
  padding: 0px;
  &.isToday {
    color: #3ebebc;
  }
}
