.button-group {
    box-shadow: 6px 6px 20px 0 rgba(83, 189, 187, 0.31);
    -webkit-box-shadow: 6px 6px 20px 0 rgba(83, 189, 187, 0.31);
    border-radius: 5px;
    background-color: $sd-background-light;

    button {
        border: none;
        border-radius: 0;
        margin-bottom: 0;
        padding: 0 $sd-spacing-lg;
        height: $element-height-sm;
        background-color: $sd-background-light;
        opacity: 1;
        font-size: 14px;
        color: $button-group-blue;
        font-family: "PlutoSans", "Helvetica";

        &:hover {
            background-color: rgba(98, 203, 201, 0.15);
        }

        &:disabled {
            cursor: default;
            border: none;
            color: #c0cbd3;
            background-color: #f7fafc;
            box-shadow: inset 1px 0px 15px 0 rgba(223, 223, 223, 0.2), inset -1px 0px 15px 0 rgba(223, 223, 223, 0.2);
            -webkit-box-shadow: inset 1px 0px 15px 0 rgba(223, 223, 223, 0.2), inset -1px 0px 15px 0 rgba(223, 223, 223, 0.2);
        }

        &.selected{
            background-color: $sd-background-light;
            color: $button-group-blue;
        }

        &:first-child {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        &:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }

        &:not(:last-child):after {
            content: "";
            margin-left: $sd-spacing-lg;
            height: 30px;
            width: 2px;
            position: absolute;
            top: 5px;
            border-left: solid 1px $grey-primary;
            z-index: 1;
        }
    }

    .react-datepicker__input-container {
        input {
            border: none;
            background-color: transparent;
            cursor: pointer;
            outline: none;
            color: $button-group-blue;
            padding: 0 $sd-spacing-xxs;
            width: 100px;
            font-family: "PlutoSans", "Helvetica";
            text-align: center;
            font-size: 14px;
        }
    }

    .react-datepicker{
        margin-top: $sd-spacing-sm;
        .react-datepicker__navigation{
            padding:0
        }
        &:before{
            z-index:-1
        }
    }
    .react-datepicker__tab-loop{
        display: inline-block;
    }
}