.notificationUM {
  .content {
    .container {
      padding: $sd-spacing-sm;
      .verifiedUserInfo {
        margin-left: 4px;
        padding: 0 $sd-spacing-sm 0 $sd-spacing-sm;
        grid-area: verifiedUserInfo;
        display: flex;
        justify-content: space-between;
        .info {
          display: flex;
          align-items: center;
          justify-content: center;
          .icon {
            margin-right: 18px;
            svg {
              vertical-align: middle;
            }
          }
          .text {
            display: table;
            .title {
              font-weight: 600;
              display: table-row;
            }
            .subtitle {
              display: table-row;
              color: #6c6c6c;
            }
          }
        }
        .checkBoxInput {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 6px;
        }
      }

      .profileSection {
        grid-area: profileSection;
        padding: 0 $sd-spacing-sm 0 $sd-spacing-sm;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: $sd-spacing-sm;
        .title {
          display: flex;
          align-items: center;
          justify-content: center;
          .icon {
            svg {
              vertical-align: middle;
            }
          }
          .text {
            margin-left: 18px;
            font-weight: 600;
          }
        }
      }

      .sendSMSLink {
        grid-area: sendSMSLink;
      }
      .sendResetPasswordLink {
        grid-area: sendResetPasswordLink;
      }
      .sendResetPasswordLink,
      .sendSMSLink {
        margin-right: auto;
        margin-left: 58px;
      }
      .copyLink {
        display: flex;
        margin-left: auto;
        &:hover {
          text-decoration: underline;
        }
        svg {
          margin-right: 6px;
          align-items: center;
        }
      }

      hr {
        grid-area: hr;
        border: none;
        border-top: 1px solid #eee;
        margin: 0 -16px;
      }
      .requestRelatedSection {
        grid-area: requestRelatedSection;
        padding: 0 $sd-spacing-sm 0 $sd-spacing-sm;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: $sd-spacing-sm;
        .title {
          display: flex;
          align-items: center;
          justify-content: center;
          .icon {
            svg {
              vertical-align: middle;
            }
          }
          .text {
            margin-left: 18px;
            font-weight: 600;
          }
        }
        .action {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          position: relative;
          .icon {
            svg {
              vertical-align: middle;
            }
          }
          .text {
            margin-left: 8px;
            font-weight: 400;
          }
          .dropdownNotification {
            &.show {
              display: block;
            }
            position: absolute;
            top: 35px;
            display: none;
            z-index: 1;
            min-width: 90px;
            border-radius: $sd-border-radius-xs;
            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
            overflow: auto;
            background-color: #ffffff;
            li {
              display: flex;
              align-items: center;
              cursor: pointer;
              margin: 5px 0px 5px 0px;
              padding: 2px $sd-spacing-xs;
              &:hover {
                background-color: rgba(218, 218, 218, 0.2);
              }
              &.active {
                background-color: rgba(218, 218, 218, 0.2);
              }
            }
          }
        }
      }
      .sendBookingSection {
        grid-area: sendBookingSection;
        padding: 0 $sd-spacing-sm 0 $sd-spacing-sm;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: $sd-spacing-sm;
        .containerCheckBoxUM {
          cursor: default;
          &.disabled {
            cursor: not-allowed;
            .checkmark {
              background-color: #e4e4e4;
            }
            .checkmark:before {
              border: solid 1px #e4e4e4;
            }
          }
          .checkmark {
            margin-left: 2px;
            top: 10px;
            cursor: pointer;
          }
          .text {
            margin-top: 0px;
            margin-left: 12px;
            color: #000000;
            .subText {
              display: flex;
              color: #6c6c6c;
            }
          }
        }
      }
      .resendBookingLink {
        grid-area: resendBookingLink;
        margin-right: auto;
        margin-left: 58px;
        text-decoration: underline;
      }
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: $sd-spacing-sm;
      grid-template-areas:
        "profileSection profileSection"
        "verifiedUserInfo verifiedUserInfo"
        "sendSMSLink copyLink"
        "sendResetPasswordLink sendResetPasswordLink"
        "hr hr"
        "requestRelatedSection requestRelatedSection"
        "sendBookingSection sendBookingSection"
        "resendBookingLink resendBookingLink";
    }
  }
  .footerContainer {
    margin: 16px;
    display: flex;
    justify-content: flex-end;
    .linkGreen {
      padding: 8px 12px;
    }
  }
}
